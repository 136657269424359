.lang-nav{
  @include font('ny');
  text-transform: uppercase;
  @include rem(margin-left, 7px);
  display: none;
    position: relative;
  .site-header__right__list+&{
    display: block;
    @include breakpoint('palm', 'xmedium') {
      display: none;
    }
  }
  @include breakpoint('palm', 'xmedium') {
    display: block;
  }
  &__select{
  background: transparent;
    border: none;
    border-radius: 0;
    outline: 0;
    overflow: hidden;
    appearance: none;
    @include font('ny');
  font-family:inherit;
      background-image: url('~images/arrow_down.svg');
      background-repeat: no-repeat;
      background-position: right;
      @include rem(padding-right, 15px);
      @include rem(padding-left, 2px);
    height: 32px;
    width:100;
    position:absolute;
    left:0;
    top:0;
    opacity:0;
      @include breakpoint('xmedium') {
        @include rem(padding-top, 1px);
        @include rem(padding-right, 12px);
    height: 33px;


}

    @include breakpoint('xmedium') {
.site-header--transparent &{
color:#fff;

      background-image: url('~images/arrow_down_white.svg');
  }
  .site-header.scrolled {
    color: #212529;
      background-image: url('~images/arrow_down.svg');
}
}
}
option{
  color:#212529;
}
ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
  li{
    margin: 0;
    padding: 0;
  
    position:relative;
    &>a{
      display: block;
      background-image: url('~images/arrow_down.svg');
      background-repeat: no-repeat;
      background-position: right;
      @include rem(padding-right, 15px);
      @include rem(padding-left, 2px);
      @include breakpoint('xmedium') {
        @include rem(padding-top, 1px);
        @include rem(padding-right, 12px);
      }
      @include breakpoint('xmedium') {
.site-header--transparent &{
color:#fff;

      background-image: url('~images/arrow_down_white.svg');
  }
   .site-header.scrolled & {
      color: #212529;
      background-image: url('~images/arrow_down.svg');
    }
  }
    }
    }
    
  
.sub-menu{
  position:absolute;
  @include rem(top, units(32/14));
  left:0;
  width:calc(100% + 2px);
  background: $brand-primary-text;   transition: all .3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  a{
    @include rem(padding-left, 2px);
    @include rem(padding-right, 2px);
    display: block;
  }
}
}

