.exhibition {

  &__content {
    position: relative;
    overflow: hidden;

    &__box {
      @include rem(max-height, 180px);
      position: relative;
      transition: all .5s ease-in;

      &.full {
        max-height: 10000px;

      }
    }



    &__showmore {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      text-align: center;
      @include rem(height, units(3));

      .icon {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        @include rem(height, 100px);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$brand-primary-text+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='$brand-primary-text',GradientType=0 ); /* IE6-9 */
      }
    }
  }
}
