$modular-scale-settings: ();
$modular-scale: ();

@mixin modular-scale-init($settings) {
  $modular-scale-settings: map-merge($modular-scale-settings, $settings) !global;
  $modular-scale: map-get($modular-scale-settings, 'scale') !global;
  @include generateFontClasses();
}

@mixin generateFontClasses() {
  @each $name, $size in $modular-scale {
    .font-#{$name} {
      @include font($name);
    }
  }
}

@mixin font($size, $scale: $base-line-height) {
  @if map-has-key($modular-scale, $size) {
    $size-px: map-get($modular-scale, $size);
    $line: strip-unit($size-px) * $base-line-height;
    @include rem(font-size, $size-px);
    @include baseline($size-px, $scale);
  } @else {
    @warn 'Available sizes are: #{map-keys($modular-scale)}.';
  }
}

@function font($size) {
  @if map-has-key($modular-scale, $size) {
    @return map-get($modular-scale, $size);
  } @else {
    @warn 'Available sizes are: #{map-keys($modular-scale)}.';
  }
}

@function calc-line-height($size) {
  @return ceil($size / $base-line-height) / $size * ($base-line-height * 1.3);
  // @return ceil($size / $base-line-height) * $size;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@mixin baseline($size, $scale) {
  // rhythm unit
  $rhythm: $base-line-height * $base-font-size / $scale;

  // number of rhythm units that can fit the size
  $lines: ceil(($size + 0.001px) / $rhythm);

  // calculate the new line-height
  $line-height: $rhythm * $lines / $size;
  @include rem(line-height, $line-height * $size);
}

@function baseline($size, $scale: $base-line-height) {
  // rhythm unit
  $rhythm: $base-line-height * $base-font-size / $scale;

  // number of rhythm units that can fit the size
  $lines: ceil(($size + 0.001px) / $rhythm);

  // calculate the new line-height
  $line-height: $rhythm * $lines / $size;
  @return $line-height;
  // @return $line-height * $base-font-size * $scale;
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
