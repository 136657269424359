.hero {
  @include rem(max-height, 380px);
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include breakpoint('palm', 'small') {
    @include rem(margin-top, 70px);
    @include rem(max-height, 250px);
  }

  &--transparent {
    @include rem(max-height, 300px);
    // background-color: blue;
    background-image: none;
    color: $neutral-grey-9;

    @include breakpoint('palm', 'small') {
      @include rem(max-height, 140px);
      margin: 0;
    }
  }

  &--big {
    @include rem(max-height, 780px);

    @include breakpoint('palm', 'small') {
      @include rem(max-height, 500px);
    }
  }

  &__content {
    @include rem(padding, 0 units(1));
    @include rem(padding-top, units(1));
    @include rem(max-height, 380px);
    color: $brand-primary-text;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(-180deg, rgba(32,36,40,0.00) 0%, $neutral-grey-9 85%);
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    z-index: 1;

    .play & {
      max-height: 100%;
      justify-content: center;
      align-items: flex-start;

      @include breakpoint('palm', 'small') {
        text-align: left;
      }
    }

    &__inner {
      .play & {
        @include breakpoint('small') {
          max-width: 50%;
        }
      }
    }

    &__small {
      @include font('ny');
      margin: 0;
      font-family: $secondary-font-family;

      @include breakpoint('palm', 'small') {
        @include font('xi');

        .icon {
          display: none;
        }
      }
    }

    @include breakpoint('palm', 'small') {
      @include rem(padding-bottom, 0);
      @include rem(max-height, 200px);
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .hero--transparent & {
      background-image: none;
      color: $neutral-grey-9;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  &--wistia-video{
    .hero__content{
      background:transparent;
      margin-bottom: 1rem;
      p:last-child{
        margin-bottom: 0;
      }
    }
  }
}
