.p0  { padding: 0 !important}
.pt0 { padding-top: 0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 }
.pl0 { padding-left: 0 }
.px0 { padding-left: 0; padding-right:  0 }
.py0 { padding-top: 0;  padding-bottom: 0 }

.p1  { @include rem(padding, units(1)) }
.pt1 { @include rem(padding-top, units(1)) }
.pr1 { @include rem(padding-right, units(1)) }
.pb1 { @include rem(padding-bottom, units(1)) }
.pl1 { @include rem(padding-left, units(1)) }
.py1 { @include rem(padding-top, units(1)); @include rem(padding-bottom, units(1)) }
.px1 { @include rem(padding-left, units(1)); @include rem(padding-right, units(1)) }

.p2  { @include rem(padding, units(2)) }
.pt2 { @include rem(padding-top, units(2)) }
.pr2 { @include rem(padding-right, units(2)) }
.pb2 { @include rem(padding-bottom, units(2)) }
.pl2 { @include rem(padding-left, units(2)) }
.py2 { @include rem(padding-top, units(2)); @include rem(padding-bottom, units(2)) }
.px2 { @include rem(padding-left, units(2)); @include rem(padding-right, units(2)) }

.p3  { @include rem(padding, units(3)) }
.pt3 { @include rem(padding-top, units(3)) }
.pr3 { @include rem(padding-right, units(3)) }
.pb3 { @include rem(padding-bottom, units(3)) }
.pl3 { @include rem(padding-left, units(3)) }
.py3 { @include rem(padding-top, units(3)); @include rem(padding-bottom, units(3)) }
.px3 { @include rem(padding-left, units(3)); @include rem(padding-right, units(3)) }

.p4  { @include rem(padding, units(4)) }
.pt4 { @include rem(padding-top, units(4)) }
.pr4 { @include rem(padding-right, units(4)) }
.pb4 { @include rem(padding-bottom, units(4)) }
.pl4 { @include rem(padding-left, units(4)) }
.py4 { @include rem(padding-top, units(4)); @include rem(padding-bottom, units(4)) }
.px4 { @include rem(padding-left, units(4)); @include rem(padding-right, units(4)) }

.p5  { @include rem(padding, units(5)) }
.pt5 { @include rem(padding-top, units(5)) }
.pr5 { @include rem(padding-right, units(5)) }
.pb5 { @include rem(padding-bottom, units(5)) }
.pl5 { @include rem(padding-left, units(5)) }
.py5 { @include rem(padding-top, units(5)); @include rem(padding-bottom, units(5)) }
.px5 { @include rem(padding-left, units(5)); @include rem(padding-right, units(5)) }

.p6  { @include rem(padding, units(6)) }
.pt6 { @include rem(padding-top, units(6)) }
.pr6 { @include rem(padding-right, units(6)) }
.pb6 { @include rem(padding-bottom, units(6)) }
.pl6 { @include rem(padding-left, units(6)) }
.py6 { @include rem(padding-top, units(6)); @include rem(padding-bottom, units(6)) }
.px6 { @include rem(padding-left, units(6)); @include rem(padding-right, units(6)) }
