.event {
  &-form {
    position: relative;

    &.loading {
      pointer-events: none;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .45);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        background-image: url('~images/svg-loaders/tail-spin.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }
  }
}
