.info-sidebar {
  &__content {
    @include color('darkest');
    position: fixed;
    right: 0;
    top: 200px;
    @include rem(width, 640px);
    background: $brand-primary-text;
    z-index: 888;
    @include rem(padding, units(2) units(4));
    font-family: $secondary-font-family;
    transform: translateX(640px) translateX(-56px);
    transition: all .3s ease-in-out;
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.05);
    @include rem(min-height, 380px);

    @include breakpoint('palm', 'small') {
      width: 100%;
      top: 50%;
      left: 0;
      transform: translate(100%, -50%);
      box-shadow: none;
      opacity: 0;
      overflow: auto;
      @include rem(padding, units(1) units(2));
      box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
      min-height: auto;
    }

    .find-us {
      text-align: right;
      @include breakpoint('palm', 'small') {
        text-align: left;
        @include rem(padding-top, units(1));
      }
    }
  }

  &.active {
    .info-sidebar__content {
      transform: translateX(0);

      @include breakpoint('palm', 'small') {
        transform: translate(0, -50%);
        opacity: 1;
      }
    }
    .info-sidebar__bg {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 1;
      // background: rgba(255,255,255,.3);
      // transition: all 1s ease;
    }
  }

  .search-open &,
  .modal-open &,
  .header-open &,
  .ReactModal__Body--open & {
    transform: translateX(1000px);
  }

  // &:hover {
  //   transform: translateX(0);
  // }

  &-btn {
    background: #F8F8F8;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.03), 0 16px 16px 0 rgba(0,0,0,0.05);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__mob {
    transition: all .3s ease-in-out;
    display: none;
    cursor: pointer;
    @include rem(width, 50px);
    @include rem(height, 50px);
    border-radius: 100%;
    position: fixed;
    @include rem(bottom, units(1));
    @include rem(right, units(1));
    z-index: 1;
    justify-content: center;
    align-items: center;
    /* Oval 4: */
    background: #F8F8F8;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.03), 0 16px 16px 0 rgba(0,0,0,0.05);
    transform: translateX(0);
    @include theme('text', 'darkest');

    @include breakpoint('palm', 'small') {
      display: flex;
    }
    .search-open &,
    .modal-open &,
    .header-open &,
    .ReactModal__Body--open & {
      transform: translateX(1000px);
    }

    &__close {
      display: none;
      cursor: pointer;
      @include rem(width, 50px);
      @include rem(height, 50px);
      border-radius: 100%;
      position: fixed;
      @include rem(bottom, units(1));
      @include rem(right, units(1));
      z-index: 2;
      justify-content: center;
      align-items: center;
      /* Oval 4: */
      background: #F8F8F8;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05), 0 8px 8px 0 rgba(0,0,0,0.03), 0 16px 16px 0 rgba(0,0,0,0.05);
      @include breakpoint('palm', 'small') {
        .info-open & {
          display: flex;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    // top: units(2);
    // left: units(2);
    // transform: translateX(-50%);
    left: 0;
    top: 0;
    width: 51px;
    height: 220px;
    padding-top: 20px;
    text-align: center;
    cursor: pointer;
    &::after {
      content: "";

    }

    @include breakpoint('palm', 'small') {
      display: none;
    }
  }

  &__lang {
    @include breakpoint('palm', 'small') {
      @include rem(padding, units(1) 0);
    }
    @include breakpoint('small') {
      position: absolute;
      bottom: units(1);
      left: units(2);
      transform: rotate(-90deg);
      transform-origin: 0% 50%;
    }

    .wpml-ls-legacy-list-horizontal {
      padding: 0;
    }

    .wpml-ls-current-language {
      text-decoration: underline;
    }

    .wpml-ls-legacy-list-horizontal li {
      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }

    .wpml-ls-legacy-list-horizontal a {
      padding: 0;
      @include rem(padding-right, units(2));
    }
  }
}
