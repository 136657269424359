/**
 * @atom Breadcrumbs
 * @section Navigation > Text
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
 *  <div class="breadcrumbs mb2">
 *    <li><a href="#">Home</a></li>
 *    <li class="current">Dinner</li>
 *  </div>
 */

.breadcrumbs {
  font-family: $secondary-font-family;
  @include font('ny');
  position: relative;

  .current {
    color: $neutral-grey-5;
  }

  li {
    position: relative;
    display: inline-block;
    @include rem(padding-right, units(2));

    a {
      &:hover {
        border-bottom: 1px dotted $neutral-grey-9;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 1px;
      @include rem(width, 24px);
      background: $neutral-grey-8;
    }

    // &:first-child {
    //   &:before {
    //     content: '';
    //     display: inline-block;
    //     background: url('~images/icons-new/home.svg');
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: contain;
    //     width: 15px;
    //     height: 15px;
    //     margin-right: 5px;
    //   }
    // }

    &:last-child {
      padding-right: 0px;
      // color: $neutral-grey-5;

      &:after {
        display: none;
      }
    }
  }
}
