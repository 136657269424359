$easing: cubic-bezier(0.23, 1, 0.32, 1);

.full-page {
  // transform: scale3d(1,1,1);
  position: relative;
  transition: all .8s $easing;
  overflow: hidden;
  z-index: 0;

  .search-open & {
    // transform: translateY(100vh) translateY(-140px);
    // -ms-transform: translateY(70%) ;
    
    pointer-events: none;
    @include breakpoint('palm', 'small') {
      // transform: translateY(100vh) translateY(-70px);
    }
  }


  .info-open & {
    // transform: scale3d(0.99, 0.99, 1);
    // opacity: .8;
    // filter: blur(12px);
    overflow: hidden;
    pointer-events: none;
  }

  &--dummy {  
    position: fixed;
    transform: translate3d(0px, 100vh, 0);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all .4s $easing;

    @include breakpoint('palm', 'small') {
      display: none;
    }

    &:first-child {
      width: calc(100vw - 240px);
      margin-left: 120px;
      background: $neutral-grey-6;
      // transition-delay: .5;
      transition-duration: 1.25s;
    }
    &:nth-child(2) {
      width: calc(100vw - 160px);
      margin-left: 80px;
      background: $neutral-grey-4;
      // transition-delay: .45s;
      transition-duration: 1.20s;
    }
    &:nth-child(3) {
      width: calc(100vw - 80px);
      margin-left: 40px;
      background: $neutral-grey-2;
      // transition-delay: .4s;
      transition-duration: 1.15s;
    }

    .search-open & {
      z-index: 0;
      @include breakpoint('small') {
        display: block;
        opacity: 1;
      }
      
      &:first-child {
        transform: translateY(100vh ) translateY(-260px);
      }
      &:nth-child(2) {
        transform: translateY(100vh ) translateY(-220px);
      }
      &:nth-child(3) {
        transform: translateY(100vh ) translateY(-180px);
      }
    }
  }

  &-container {
    opacity: 0;
    transition: all .65s $easing;
    z-index: -2;
    backface-visibility: hidden;

    .search-open & {
      opacity: 1;
      z-index: 0;
    }
  }
}
