.textarea, textarea {
  @include rem(padding, units(.5) 0);
  background: transparent;
  font-family: $secondary-font-family;
  min-height: 80px;
  height: auto;
  border: 0;
  outline: 0;
  -webkit-border-radius:0px;
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid $neutral-grey-7;
  caret-color: $neutral-grey-9;
  resize: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    @include disableAutoFill;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}
