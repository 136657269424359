.search-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  // display: none;
  opacity: 1;
  // transition: all .4s .8s ease-in;
  z-index: 2;

  .text-input--search {
    pointer-events: none;
    visibility: hidden;
  }

  .search-open & {
    opacity: 1;
    .text-input--search {
      visibility: visible;
      pointer-events: auto;
    }
  }

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 1;
    z-index: -1;
    backface-visibility: hidden;

    .search-open & {
      z-index: 0;
    }
  }

  &__close {
    position: absolute;
    @include rem(top, units(4));
    @include rem(right, units(2));
    @include breakpoint('palm', 'small') {
      @include rem(top, units(2));
    }

    .fa {
      color: $brand-primary-text;
      font-size: 26px;
    }

  }

  &__content {

    .tag-collection {
      @include breakpoint('palm', 'small') {
        display: none;
      }
    }
  }
}
