/**
 * @molecule Accordion
 * @section Navigation > Accordion
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
 *  <div class="accordion">
 *    <div class="accordion__item">
 *      <dt>
 *        <a href="#">
 *          <h6><i>Entrance</i></h6>
 *          <p class="small">Prices & Opening hours</p>
 *          <i class="icon icon-chevron-down" aria-hidden="true"></i>
 *        </a>
 *        <dd>
 *          <p>Admission buy easiest through our app (Google Play or the App Store). Then you can easily and quickly pass the  entrance with your ticket for the day when you made the purchase. Of course you can also buy admission ticket on the spot.</p>
 *          <p><i>For members of the Photographic family is always free admission and membership gets you easily through our app.</i></p>
 *        </dd>
 *      </dt>
 *    </div>
 *  </div>
 */

.accordion {

  &--cart {
    .accordion__item {
      dd {
        @include rem(padding, units(2));
      }
    }
  }

  &__item {
    @include rem(margin-bottom, units(1));

    &__title {
      @include rem(padding-right, units(6));

      .accordion--cart & {
        margin: 0;
      }
    }

    &__arrow {
      @include rem(height, units(1.5));
      @include rem(width, units(2.5));
      background-image: url('~images/icons-new/down.svg');
      background-repeat: no-repeat;
      background-size: contain;
      // transition: all .3s ease-in;
      // transform-origin: 50% 50%;
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translate(-50%, -50%);
    }

    dt,
    &__header {
      @include rem(padding, units(2));
      width: 100%;
      // min-height: 120px;
      background: $neutral-grey-1;
      border-left: 5px solid $neutral-grey-5 ;
      display: flex;
      flex-flow: column wrap;
      // align-items: center;
      justify-content: center;
      vertical-align: middle;
      position: relative;

      &:last-of-type {
      }

      p {
        margin: 0;
      }

      a {
        &.active {
          .accordion__item__arrow {
            // transform: rotate(180deg) translate(-50%, -50%);
            background-image: url('~images/icons-new/up.svg');
          }
        }
      }

      &.active {
        .accordion__item__arrow {
          // transform: rotate(180deg) translate(-50%, -50%);
          background-image: url('~images/icons-new/up.svg');
        }
      }
    }

    dd,
    &__content {
      @include rem(padding, units(4));
      @include rem(margin-bottom, units(1));
      border-top: 0;
      /* Rectangle Copy: */
      background: $neutral-grey;

      &:last-of-type {
        position: relative;
      }
    }

  }
}
