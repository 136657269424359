.price-table {

  &__item-container {
    @include rem(margin-bottom, units(2));
    display: flex;
  }

  &__item {
    @include rem(margin-bottom, units(1));
    background: $neutral-grey-1;
    font-family: $secondary-font-family;
    display: flex;
    flex-flow: column wrap;
    height: 100%;

    &__image {
      text-align: center;
      @include rem(padding, units(2) units(2) units(1) units(2));
    }

    &__content {
      @include rem(padding, 0 units(2));
      flex: 1 1 auto;

      @include breakpoint('small', 'medium') {
        text-align: left;
      }
      @include breakpoint('palm', 'small') {
        text-align: center;
      }
    }

    &__buttons {

      &__btn {
        @include rem(padding, units(1) units(2));
        border-top: 1px solid $neutral-grey-3;
        display: block;
        width: 100%;
        background: $neutral-grey-7;
        color: $brand-primary-text;
        position: relative;
        cursor: pointer;
        pointer-events: auto;

        &:after {
          @include rem(right, units(.5));
          @include font('ny');
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-family: 'FGIcons';
          content: '\e90c';

          .no-cta & {
            display: none;
          }
        }

      }
    }
    .btn {
      justify-self: flex-end;
    }
  }
}
