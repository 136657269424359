@font-face {
  font-family: 'FGIcons';
  src:  url('~fonts/icomoon.eot?i6kxzx');
  src:  url('~fonts/icomoon.eot?i6kxzx#iefix') format('embedded-opentype'),
    url('~fonts/icomoon.ttf?i6kxzx') format('truetype'),
    url('~fonts/icomoon.woff?i6kxzx') format('woff'),
    url('~fonts/icomoon.svg?i6kxzx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


.icon {
  color: inherit;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FGIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: $base-font-size;
  text-transform: none;
  line-height: 1;
  vertical-align: sub;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--lg {
    @include font('zeta', 1);
  }

  &--md {
    @include font('theta', 1);
  }

  &--sm {
    @include font('kappa', 1);
  }

  &--xs {
    @include font('ny', 1);
  }

  &--white {
    color: $brand-primary-text;
  }

  &-back:before {
    content: '\e900';
  }

  &-burger:before {
    content: '\e901';
  }

  &-calendar:before {
    content: '\e902';
  }

  &-cart:before {
    content: '\e903';
  }

  &-chat:before {
    content: '\e904';
  }

  &-checkmark:before {
    content: '\e905';
  }

  &-clock:before {
    content: '\e906';
  }

  &-close:before {
    content: '\e908';
  }

  &-down:before {
    content: '\e909';
  }

  &-edit:before {
    content: '\e90a';
  }

  &-food:before {
    content: '\e90b';
  }

  &-forward:before {
    content: '\e90c';
  }

  &-heart-filled:before {
    content: '\e90d';
  }

  &-heart:before {
    content: '\e90e';
  }

  &-home:before {
    content: '\e90f';
  }

  &-info:before {
    content: '\e910';
  }

  &-map-marker:before {
    content: '\e911';
  }

  &-map:before {
    content: '\e912';
  }

  &-minus:before {
    content: '\e913';
  }

  &-moon:before {
    content: '\e914';
  }

  &-play:before {
    content: '\e915';
  }

  &-plus:before {
    content: '\e916';
  }

  &-profile:before {
    content: '\e917';
  }

  &-search:before {
    content: '\e919';
  }

  &-subway:before {
    content: '\e91a';
  }

  &-sun:before {
    content: '\e91b';
  }

  &-text:before {
    content: '\e91c';
  }

  &-ticket:before {
    content: '\e91d';
  }

  &-up:before {
    content: '\e91e';
  }
  &-clock.mr1{
    display: inline-block;
    vertical-align: top;
    @include rem(margin,0px 21px 0 8px);
    @include breakpoint('palm', 'small') {
      @include rem(margin, 0px 22px 0px 5px);
    }
  }
}
