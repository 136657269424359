.tooltip {
  position: relative;
  font-family: $secondary-font-family;
  background: $neutral-grey-8;
  color: $brand-primary-text;
  display: inline-block;
  border-radius: 3px;
  @include font('ny');
  line-height: 1.2;
  @include rem(letter-spacing, .47px);
  @include rem(padding, units(2) units(3));
  // text-transform: uppercase;
  // font-weight: $font-weight-bold;
  text-align: center;

  &.shadow {
    box-shadow: 0 12px 22px 0 rgba(0,0,0,0.24);
  }

  &:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 5px;
  }

  &.up {
    &:after {
      bottom: 100%;
      left: 50%;
      border-color: transparent;
      border-bottom-color: $neutral-grey-8;
      margin-left: -5px;
    }
  }

  &.down {
    &:after {
      top: 100%;
      left: 50%;
      border-color: transparent;
      border-top-color: $neutral-grey-8;
      margin-left: -5px;
    }
  }

  &.left {
    &:after {
      right: 100%;
      top: 50%;
      border-color: transparent;
      border-right-color: $neutral-grey-8;
      margin-top: -5px;
    }

  }

  &.right {
    &:after {
      left: 100%;
      top: 50%;
      border-color: rgba(213, 213, 213, 0);
      border-left-color: $neutral-grey-8;
      margin-top: -5px;
    }
  }

  &--inverted {
    background: $neutral-grey-3;
    color: $neutral-grey-8;
    &.up {
      &:after {
        border-bottom-color: $neutral-grey-3;
      }
    }
    &.down {
      &:after {
        border-top-color: $neutral-grey-3;
      }
    }
    &.left {
      &:after {
        border-right-color: $neutral-grey-3;
      }
    }
    &.right {
      &:after {
        border-left-color: $neutral-grey-3;
      }
    }
  }

  &--secondary {
    background: $brand-primary-text;
    border: 1px solid #DDDDDD;
    color: $neutral-grey-8;

    &.up {
      &:after {
        border-bottom-color: #DDDDDD;
      }
    }
    &.down {
      &:after {
        border-top-color: #DDDDDD;
      }
    }
    &.left {
      &:after {
        border-right-color: #DDDDDD;
      }
    }
    &.right {
      &:after {
        border-left-color: #DDDDDD;
      }
    }
  }
}
