.sidebar-filter {
  transform: translate3d(0, 0, 0);

  @include breakpoint('palm', 'small') {
    display: none;
    // transform: translateX(-300px);
    // @include rem(padding, units(1));
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background-color: #fff;
    // z-index: 999;
  }

  &.active {
    display: block;
  }
}
