@import 'react-phone-number-input/style.css';


:root {
  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInputCountrySelect-marginRight: 8px;
}

.PhoneInput {
  &.text-input {
    display: flex;
  }
  &--focus {
    //box-shadow: 0 1px 0 0 $neutral-grey-7;
    border-color: #3737E6;
  }
}

.PhoneInputInput {
  @extend .text-input;
  border-bottom: none;
  padding: 0;
  min-height: 40px;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

}

.PhoneInputCountryIcon {
  width: 22px;
  height: 14.67px;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  color: #5a5a5a;
  opacity: 1;
}

.PhoneInputCountrySelectArrow {
  margin-left: 8px;
  color: #5a5a5a;
  opacity: 1;
}
