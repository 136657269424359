.only-desk {
  @include breakpoint('palm', 'small') {
    display: none;
  }
}

.only-palm {
  @include breakpoint('small') {
    display: none;
  }
}
