/**
 * @structure Wrapper
 * @section Navigation > Layout
 * @description
 *  Wrapper is the starting div for every page
 * @markup
 *  <div class="wrapper"></div>
 */

.wrapper {
  @include rem(margin-top, 150px);

  @include breakpoint('palm', 'xmedium') {
    @include rem(margin-top, 70px);
  }

  .webview & {
    margin-top: 0;
  }
}


.app-wrapper {
  padding-top: 45px;
  padding-bottom: 90px;

  //padding-left: 20px;
  //padding-right: 20px
  //

  & > .row {
    padding-left: 20px;
    padding-right: 20px;
  };

  @include breakpoint('palm', 'small') {
    padding-top: 15px;
    padding-bottom: 45px;
  }
}
