.newsletter {
  position: relative;

  &.loading {
    pointer-events: none;
    opacity: .7;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 38px;
      height: 38px;
      transform: translate(-50%, -50%);
      background-image: url('~images/svg-loaders/tail-spin.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: 99;
    }
  }
}
