.product-section {
  .simple-product,
  .woocommerce-variation-add-to-cart {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__specs {
    &__stock {
      color: $utility-junglegreen;

      &.outofstock,
      &.out-of-stock {
        color: $utility-pomegranate;
      }
    }
  }

  &__price {
    @include breakpoint('palm', 'large') {
      flex: 1 1 100%;
    }
    @include breakpoint('small') {
      // text-align: right;
    }

    &__member {
      color: $neutral-grey-5;
    }
  }

  &__tags {

    &__fav {
      @include rem(margin-right, units(.5));
      display: inline-block;
      width: 19px;
      height: 19px;
      background-image: url('~images/icons-new/heart.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      vertical-align: middle;
      transition: all .3s ease-in-out;

      &.favorite-selected {
        background-image: url('~images/icons-new/heart-filled.svg');
      }
    }
  }

  &__frames {
    // @include rem(padding, units(2) 0);
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    &__item {
      @include rem(height, 40px);
      @include rem(width, 40px);
      @include rem(margin-left, units(1));
      border-radius: 100%;
      position: relative;

      &.checked {

        &:after {
          content: '';
          top: 50%;
          left: 50%;
          position: absolute;
          background-image: url('~images/icons-new/checkmark.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% 100%;
          height: 15px;
          width: 15px;
          transform: translate(-50%, -50%);
        }
      }

      &.white {
        border: 1px solid $neutral-grey-4;
        background-color: $brand-primary-text;
      }

      &.grey {
        border: 1px solid $neutral-grey-5;
        background-color: $neutral-grey-5;
      }

      &.dark {
        border: 1px solid $neutral-grey-8;
        background-color: $neutral-grey-8;
      }

      &.no-frame {
        border: 1px solid #000000;
        background-color: $brand-primary-text;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: #000000;
          transform: rotate(-45deg);
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__people {
    @include breakpoint('palm', 'small') {
      text-align: center;
    }

    &__text {
      color: $neutral-grey-9;
    }

    h6 { color: $neutral-grey-9 }

  }
}
