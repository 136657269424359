@import 'flatpickr/dist/flatpickr.min.css';

.calendar {

  &__datepicker {
    @include breakpoint('palm', 'small') {
      @include rem(margin-bottom, units(2));
      order: -1;
    }
  }
}

.flatpickr-calendar {
  @include rem(margin-top, units(1));
  @include rem(padding, units(1));
  @include rem(width, 335px);
  font-family: $secondary-font-family;
  border: 0;
  border-radius: 0;
  position: absolute;
  touch-action: manipulation;
  background: rgba($neutral-grey, .98);
  box-shadow: none;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier( .23, 1,  .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.hasWeeks {
  width: auto;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: none;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  display: none;
}


.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-month {
  @include rem(padding, units(2));
  @include rem(height, units(3));
  display: flex;
  align-items: center;
  color: $neutral-grey-9;
  fill: $neutral-grey-9;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  @include rem(padding, units(1));
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  z-index: 3;
}

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative;
}

.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  /*
  /*rtl:begin:ignore*/
  /*
  */
  left: 0;
  /*
  /*rtl:end:ignore*/
  /*
  */
}

/*
/*rtl:begin:ignore*/
/*
/*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  /*
  /*rtl:begin:ignore*/
  /*
  */
  right: 0;
  /*
  /*rtl:end:ignore*/
  /*
  */
}

/*
/*rtl:begin:ignore*/
/*
/*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #eee;
}

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: lighten($neutral-grey-9, 40%);
}

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px;
}

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  transition: fill  .1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(255,255,255, .05);
}

.numInputWrapper span:hover {
  background: rgba(192, 187, 167, .1);
}

.numInputWrapper span:active {
  background: rgba(192,187,167, .2);
}

.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
  top: 33%;
}


.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(255,255,255, .6);
}


.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255,255,255, .6);
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(255,255,255, .5);
}

.numInputWrapper:hover {
  background: rgba(192,187,167, .05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month.slideLeft {
  transform: translate3d(-100%, 0, 0);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier( .23, 1,  .32, 1);
}

.flatpickr-current-month.slideLeftNew {
  transform: translate3d(100%, 0, 0);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier( .23, 1,  .32, 1);
}

.flatpickr-current-month.slideRight {
  transform: translate3d(100%, 0, 0);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier( .23, 1,  .32, 1);
}

.flatpickr-current-month.slideRightNew {
  transform: translate3d(0, 0, 0);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier( .23, 1,  .32, 1);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(192,187,167, .05);
}

.flatpickr-current-month .numInputWrapper {
  // width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: $brand-primary-text;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: $brand-primary-text;
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  color: inherit;
  cursor: default;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: initial;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba($neutral-grey-9, .5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: $neutral-grey-9;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.flatpickr-calendar.animate .dayContainer.slideLeft {
  animation: fpFadeOut 400ms cubic-bezier(.23, 1,  .32, 1), fpSlideLeft 400ms cubic-bezier(.23, 1,  .32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  transform: translate3d(-100%, 0, 0);
}

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  animation: fpFadeIn 400ms cubic-bezier(.23, 1,  .32, 1), fpSlideLeft 400ms cubic-bezier(.23, 1,  .32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRight {
  animation: fpFadeOut 400ms cubic-bezier(.23, 1,  .32, 1), fpSlideRight 400ms cubic-bezier(.23, 1,  .32, 1);
  transform: translate3d(100%, 0, 0);
}

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  animation: fpFadeIn 400ms cubic-bezier(.23, 1,  .32, 1), fpSlideRightNew 400ms cubic-bezier(.23, 1,  .32, 1);
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  color: rgba($neutral-grey-9, .95);
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: rgba($neutral-grey-7, .98);
  border-color: rgba($neutral-grey-7, .98);
  color: $brand-primary-text;
}

.flatpickr-day.today {
  border-color: #eee;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #eee;
  background: #eee;
  color: #3f4458;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $neutral-grey-7;
  box-shadow: none;
  color: $brand-primary-text;
  border-color: $neutral-grey-7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 $neutral-grey-7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 rgba(100,108,140, .98), 5px 0 0 rgba(100,108,140, .98);
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  pointer-events: none;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba($neutral-grey-9, .3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 $neutral-grey-7, 5px 0 0 $neutral-grey-7;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #3f4458;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none;
}

.flatpickr-innerContainer {
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  overflow: hidden;
  display: flex;
}

.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: $neutral-grey-9;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: $neutral-grey-9;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: $neutral-grey-9;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: rgba(255, 255, 255, .95);
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: rgba(109, 118, 151, .98);
}

.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fpSlideLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fpSlideLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes fpSlideLeftNew {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpSlideLeftNew {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fpSlideRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fpSlideRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes fpSlideRightNew {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpSlideRightNew {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fpFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fpFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@-webkit-keyframes fpFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fpFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
