.login, .register {
  border: 0 !important;
  padding: 0 !important;

  &.loading_state {

    .login__loader {
      display: block;
    }
  }

  &__loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.7);

    &__spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
    }

    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   background-color: $neutral-grey-9;
    //   mask-image: url('~images/svg-loaders/tail-spin.svg');
    //   mask-repeat: no-repeat;
    //   mask-size: auto;
    //   mask-position: center;
    //   // background-image: url('~images/svg-loaders/audio.svg');
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   background-position: center;
    //   width: 50px;
    //   height: 50px;
    // }
  }
}


.login-and-registration-switch {
  position: relative;

  .login-container,
  .registration-container {

  }

  .login-container {
    display: none;
    &.active {
      display: block;
    }
  }
  .registration-container {
    display: none;
    &.active {
      display: block;
    }
  }
  &.login-active {
    .login-container {
      display: block;
    }
    .registration-container {
      display: none;
    }
  }
  &.registration-active {
    .login-container {
      display: none;
    }
    .registration-container {
      display: block;
    }
  }
}
