.share {
  height: 100%;
  @media (min-width: 1000px) {
    position: absolute;
  }
  & > div {
    color: $neutral-grey-5;
  }
  &__title {
    @include rem(margin-bottom, units(0.2));
  }

  &__item {
    display: inline-block;
    @include rem(margin-bottom, units(2));
    @include rem(margin-right, units(.5));
  }

  @media (min-width: 1000px) {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column wrap;
      width: 50px;
      position: absolute;
      margin-left: -160px;
      @media(max-width: 1270px) {
        margin-left: -120px;
      }
      @media(max-width: 1080px) {
        margin-left: -100px;
      }
    }
    &.share-fixed {
      & > div {
        position: fixed;
        top: 120px;
      }
    }
    &.share-bottom {
      & > div {
        position: absolute;
        bottom: 0;
        top: auto;
      }
    }
    &__title {
      @include rem(margin-bottom, units(1));
    }

    &__item {
      @include rem(margin-bottom, units(0.5));
      @include rem(margin-right, units(0));
    }
  }
}
