.top-bar {
  &:empty {
    display: none;
  }
  background-color: #000;
  color: #fff !important;
  font-size: 1rem;
  padding: 10px 40px;
  text-align: center;
  font-style: italic;
  display: flex;
  flex-direction: column;

  @include if-theme('fgny') {
    .content {
      *, h1, h2, h3, h4, h5, h6, h7 {
        color: #fff;
      }
      div > a, p > a, span > a, strong > a {
        display: inline-block;
        color: #fff;
        text-decoration: none !important;
        line-height: 22px;
        border-bottom: 1px solid #fff;
      }
    }
  }

  blockquote, blockquote > p {
    color: $neutral-grey-6 !important;
  }



  strong {
    font-style: normal;
    font-weight: 800;
  }

  a {
    color: white !important;
  }

  .nowrap {
    white-space: nowrap;
    display: inline;
  }

  &--inner {
    align-self: center;
    @media (max-width: 1680px) {
      max-width: 760px;
    }
    @media (max-width: 860px) {
      max-width: 630px;
    }
  }
}
