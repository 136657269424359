.switch {
  @include rem(margin, units(1));
  display: inline-block;
  position: relative;
  @include rem(width, 50px);
  -webkit-user-select: none;
  -moz-user-select:none;
  -ms-user-select: none;


  &__checkbox {
    display: none;

    &:checked + .switch__label .switch__button {
      right: 0;
      background: $neutral-grey-8;
    }

    &:checked + .switch__label .switch__inner {
      margin-left: 0;
    }
  }

  &__label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    @include rem(border-radius, 20px);
  }

  &__inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before, &:after {
      display: block;
      float: left;
      width: 50%;
      @include rem(height, units(1.3));
      padding: 0;
    }

    &:before {
      content: "";
      @include rem(padding-left, units(1));
      background-color: $neutral-grey-8;
    }

    &:after {
      content: "";
      @include rem(padding-right, units(1));
      background-color: $neutral-grey-4;
    }
  }

  &__button {
    display: block;
    @include rem(padding, units(.9));
    @include rem(margin, -3px);
    background: $neutral-grey-5;
    position: absolute;
    bottom: 0;
    @include rem(right, 30px);
    border-radius: 100%;
    transition: all 0.3s ease-in 0s;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24), inset 0px 0px 0px 5px $neutral-grey-1;
  }
}
