$fgny-base-font-color: #000;
$fgny-h-font-color: #000;

@mixin fgny-h($font-size: 40px, $color: $fgny-h-font-color) {
  @include if-theme('fgny') {
    font-weight: normal;
    font-family: $secondary-font-family;
    font-size: $font-size;
    line-height: 0.8;
    @if $color {
      color: $color;
    }
    @content;
  }
}

@mixin fgny-sub-h($font-size: 40px, $color: $fgny-base-font-color, $font-style: italic) {
  @include if-theme('fgny') {
    font-weight: normal;
    font-family: $base-font-family;
    font-style: $font-style;
    font-size: $font-size;
    @if $color {
      color: $color;
    }
    @content;
  }
}

@mixin fgny-b1($font-size: 20px, $color: $fgny-base-font-color) {
  @include if-theme('fgny') {
    font-weight: normal;
    font-family: $base-font-family;
    font-size: $font-size;
    @if $color {
      color: $color;
    }
    @content;
  }
}

@mixin fgny-text-button($color: #f5f2f0) {
  font-weight: normal;
  font-family: $secondary-font-family;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @if $color {
    color: $color;
  }
}

@mixin fgny-b2($font-size: 15px, $color: $fgny-base-font-color) {
  @include fgny-b1($font-size: $font-size, $color: $color) {
    @content
  };
}

@mixin fgny-dark {
  @include if-theme('fgny') {
    color: $fgny-base-font-color;
    @content;
  }
}

@mixin fgny-light {
  @include if-theme('fgny') {
    color: $fgny-h-font-color;
    @content;
  }
}

@mixin fgny-h1 {
  @include fgny-h($font-size: 180px);
}
@mixin fgny-h2 {
  @include fgny-h($font-size: 120px);
}
@mixin fgny-h3 {
  @include fgny-h($font-size: 80px);
}
@mixin fgny-h4 {
  @include fgny-h($font-size: 60px);
}
@mixin fgny-h5 {
  @include fgny-h($font-size: 40px);
}
@mixin fgny-h6 {
  @include fgny-h($font-size: 20px);
  @include if-theme('fgny') {
    letter-spacing: 1px;

  }
}

@mixin fgny-sub-h1 {
  @include fgny-sub-h($font-size: 60px);
}
@mixin fgny-sub-h2 {
  @include fgny-sub-h($font-size: 30px);
}
@mixin fgny-sub-h3 {
  @include fgny-sub-h($font-size: 30px, $font-style: normal);
}
@mixin fgny-sub-h4 {
  @include fgny-sub-h($font-size: 40px);
}


.base-font,
.base-font > p {
  @include fgny-b1;
}

.secondary-font {
  @include fgny-b1;
}


.text--b2 {
  @include fgny-b2
}

h1,
.text--h1 {
  @include fgny-h1;
}

h2,
.text--h2 {
  @include fgny-h2;
}

h3,
.text--h3 {
  @include fgny-h3;
}

h4,
.text--h4 {
  @include fgny-h4;
}

h5,
.text--h5 {
  @include fgny-h5;
}

h6,
.text--h6 {
  @include fgny-h6;
}

.text--light {
  @include fgny-light;
}

.text--dark {
  @include fgny-dark;
}

.text--sub-h1 {
  @include fgny-sub-h1;
}
.text--sub-h2 {
  @include fgny-sub-h2;
}
.text--sub-h3 {
  @include fgny-sub-h3;
}
.text--sub-h4 {
  @include fgny-sub-h4;
}

@include if-theme('fgny') {

  .btn {
    & + .btn {
      margin-left: 15px;
    }
  }

  .text--uppercase {
    text-transform: uppercase;
  }

  b, strong {
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }



  .truncate {
    @include text-truncate;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .font-weight-light {
    font-weight: $font-weight-light;
  }
  .font-weight-medium {
    font-weight: $font-weight-medium;
  }
  .font-weight-semibold {
    font-weight: $font-weight-semibold;
  }
  .font-weight-bold {
    //font-weight: $font-weight-bold;
  }

  .heading {
    &--alt {
      @include fgny-b1;
    }

    &--small {
      @include font('ny');
      padding: 0;
      margin: 0;
      text-transform: uppercase;
      //font-weight: $font-weight-bold;
      @include fgny-b1;
    }
  }

  a {
    text-decoration: none;
    color: $fgny-base-font-color;
    &.link--underlined {
      display: inline-block;
      color: $fgny-base-font-color;
      text-decoration: none !important;
      line-height: 22px;
      border-bottom: 1px solid $fgny-base-font-color;
    }
  }


  p, .p {
    //& + p, & + .p {
    //  margin-top: $base-line-height-px;
    //}
    //@include font('ny', 2);
    // @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('my'), font('lambda'));
    //@include rem(margin-bottom, units(2));

    &.small {
      //@include font('ny', 2);
      // @include fluid-type(font-size, breakpoint('small'), breakpoint('wide'), font('xi'), font('ny'));
      @include fgny-b2();
    }

    &.lead, .lead & {
      //@include font('jota');
      @include fgny-b1;
    }

    a {
      &:not(.btn) {
        text-decoration: underline;
      }
    }

    &.error {
      color: $utility-pomegranate;
    }
  }

  .no-decoration {
    text-decoration: none;
  }

  .p-small {
    p {
      @include font('ny', 2);
      @include fgny-b1;
    }
  }

  blockquote {
    position: relative;

    &:before {
      content: '';
      display: block;
      background-color: $neutral-grey-5;
      position: absolute;
      top: 8px;
      height: calc(100% - 19px);
      left: -30px;
      width: 5px;

      @include breakpoint('palm', 'medium') {
        top: 10px;
        height: calc(100% - 18px);
      }

      @media(max-width: 880px) {
        left: -14px;
      }

      @include breakpoint('palm', 'small') {
        top: 8px;
        height: calc(100% - 14px);
      }
    }

    &:not(.big) {
      color: $neutral-grey-6;
    }

    &.big {
      + p {
        @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('jota'), font('zeta'));
        @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('jota')), baseline(font('zeta')));
      }

      @include breakpoint('palm', 'medium') {
        padding: 0 10px;
      }

      &:before {
        background-color: $neutral-grey-7;
        left: -50px;

        @include breakpoint('palm', 'medium') {
          top: 10px;
          height: calc(100% - 18px);
          left: -7px;
        }

        @include breakpoint('palm', 'small') {
          top: 7px;
          height: calc(100% - 13px);
        }
      }
    }
  }

  blockquote {
    &.big p {
      @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('jota'), font('zeta'));
      @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('jota')), baseline(font('zeta')));
      display: inline-block;
    }
  }

  .t-text--note {
    color: #F22613;
  }

}
