$input-border-style: 1px solid $neutral-grey-7;
@include if-theme('fgny') {
  $input-border-style: 2px solid #000 !global;
}

.text-input {
  @include rem(padding, units(.5) 0);
  background: transparent;

  &[name=access_code] {
    &::placeholder {
      @include breakpoint('palm', 'medium') {
        font-size: 16px !important;
      }
    }
  }

  @include if-theme() {
    font-family: $secondary-font-family;
    min-height: 55px;

    //-webkit-box-shadow: inset 0 0 0 50px #A5A5A5;
    //-webkit-text-fill-color: #000;

  }
  @include fgny-b1;
  @include if-theme('fgny') {
    padding: 0;
    min-height: 46px;
  }

  &::placeholder {
    @include if-theme('fgny') {
      color: #5A5A5A;
      opacity: 1;
    }
  }

  border: 0;
  outline: 0;
  -webkit-border-radius: 0;
  appearance: none;
  border-radius: 0;
  border-bottom: $input-border-style;
  caret-color: $neutral-grey-9;
  // line-height: 1;
  -moz-appearance: textfield;

  &.capitalize {
    &::-webkit-input-placeholder {
      text-transform: none;
    }

    &:-moz-placeholder {
      text-transform: none;
    }

    &:-ms-input-placeholder {
      text-transform: none;
    }
  }

  &:focus,
  &:active {
    border-color: #3737E6;
    outline: none;
    //box-shadow: 0 1px 0 0 $neutral-grey-7;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    @include disableAutoFill;

    .box--grey & {

      //@include backgroundColorAutoFill($neutral-grey-1);
      @include if-theme('fgny') {
        @include disableAutoFill;
      }
    }
  }

  @include breakpoint('palm', 'small') {

    &[type='date'] {
      &:not([value]) {
        &:before {
          @include rem(margin-right, units(1));
          color: lighten(color('darkest'), 30%);
          content: attr(placeholder);
        }
      }

      &:valid {
        &:before {
          content: '';
          margin-right: 0;
        }
      }

      &:focus {
        &:before {
          content: '';
          margin-right: 0;
        }
      }
    }

    &[type=number] {
      -moz-appearance: textfield;
    }

    &[type='time'] {
      &:not([value]) {
        &:before {
          @include rem(margin-right, units(1));
          color: lighten(color('darkest'), 30%);
          content: attr(placeholder);
        }
      }

      &:valid {
        &:before {
          content: '';
          margin-right: 0;
        }
      }

      &:focus {
        &:before {
          content: '';
          margin-right: 0;
        }
      }
    }
  }

  &:disabled {
    color: $neutral-grey-5;
    border-bottom: 1px solid $neutral-grey-5;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.error {
    color: #E30D0D;
    border-bottom: 1px solid #E30D0D;

    &:focus,
    &:active {
      //box-shadow: 0 1px 0 0 #E30D0D;
      border-color: #3737E6;
    }
  }

  &--search {
    @include font('alpha');
    color: $neutral-grey;
    border-bottom: 1px solid $neutral-grey;

    @include breakpoint('palm', 'small') {
      @include font('kappa');
    }

    &:focus,
    &:active {
      @include textColorAutoFill($neutral-grey);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      @include textColorAutoFill($neutral-grey);
    }
  }


  &-group {
    display: flex;
    align-items: center;
    position: relative;

    .text-input {
      width: 100%;
    }

    &__submit {
      border: 0;
      outline: 0;
      background: none;
      position: absolute;
      right: 0;
      cursor: pointer;
    }

    &__icon {
      position: absolute;
      right: 0;
    }
  }
}
