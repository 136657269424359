.xs {
  &--hidden {
    @include breakpoint('palm', 'small') {
      display: none;
    }
  }
  &--visible {
    @include breakpoint('palm', 'small') {
      display: block;
    }
  }
}

.sm {
  &--hidden {
    @include breakpoint('small', 'medium') {
      display: none;
    }
  }
  &--visible {
    @include breakpoint('small', 'medium') {
      display: block;
    }
  }
}

.md {
  &--hidden {
    @include breakpoint('medium', 'large') {
      display: none;
    }
  }
  &--visible {
    @include breakpoint('medium', 'large') {
      display: block;
    }
  }
}

.lg {
  &--hidden {
    @include breakpoint('large') {
      display: none;
    }
  }
  &--visible {
    @include breakpoint('large') {
      display: block;
    }
  }
}
