.shop-card {
  height: 285px;
  width: 100%;
  max-width: 100%;
  position: relative;
  background-image: url('~images/shop-card.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: $secondary-font-family;
  display: flex;

  &--container {
    @include rem(margin-bottom, units(1));
    display: flex;
    flex-flow: column wrap;
    height: 100%;
  }

  &__content {
    @include theme('text', 'brand-primary');
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    &__title {
      @include font('my');
      @include rem(padding-bottom, units(1));
      margin: 0;
      text-transform: uppercase;
      z-index: 9;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 1);
      background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.66) 18%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-gradient(left bottom, left top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(18%, rgba(0, 0, 0, 0.66)), color-stop(100%, rgba(0, 0, 0, 0)));
      background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.66) 18%, rgba(0, 0, 0, 0) 100%);
      background: -o-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.66) 18%, rgba(0, 0, 0, 0) 100%);
      background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.66) 18%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.66) 18%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    }
  }

  &__desc {
    @include rem(padding, units(1));
    background-color: #F1F3F5;
    display: block;
    width: 100%;
    text-align: left;
    flex: 1 1 auto;
    @include breakpoint('palm', 'small') {
      @include rem(margin-bottom, units(1));
    }
  }
}
