.thumb-text-blocks{
  &__row{
    margin: 0;
    background: $neutral-grey-10;
    border:0 solid $brand-primary-text;
    @include rem(border-width, 10px 0 0 0);

    @include breakpoint('palm', 'medium') {
      @include rem(border-width, 0 0 0 0);
    }
    &:last-child{
      @include rem(border-width, 11px 0 10px 0);
      @include breakpoint('palm', 'medium') {
        @include rem(border-width, 0 0 11px 0);
      }
      @include breakpoint('palm', 'small') {
        @include rem(border-width, 0 0 5px 0);
      }
    }
  }
}
