/**
 * @molecule Box
 * @section Navigation > Cards and boxes
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @modifiers
 *  .box--light
 *  .box--grey
 *  .box--no-shadow
 *  .box--wide
 * @markup
 *  <div class="box box--grey">
 *    <h5>This is a box</h5>
 *  </div>
 */

.box {
  @include rem(padding, units(1));
  width: 100%;
  color: $brand-primary-text;
  background: $neutral-grey-9;
  box-shadow: 15px 15px 0 0 rgba(52, 58, 64, 1);

  &--light {
    color: $neutral-grey-9;
    background: $brand-primary-text;
  }

  &--grey {
    color: $neutral-grey-9;
    background: $neutral-grey-1;
    @include if-theme('fgny') {
      background: none;
      padding: 0;
    }
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--wide {
    @include breakpoint('small') {
      width: 70%;
    }
  }

  //@include fgny-b2;
}



