.logo {
  transition: width .6s ease-out,opacity .7s ease-out .5s,transform .7s ease-out .5s;
  @include breakpoint('palm', 'small') {
    width: 120px;
    height: auto;
  }
}
.branding .logo {
  @include breakpoint('palm', 'small') {
    padding-top: #{(54px - 44px)/2};
  }

}