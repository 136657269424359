.js-animate {
  opacity: 0;

  &.animated {
    opacity: 1;
  }
  &.will-change {
    will-change: transform, opacity;
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
  animation-duration: 0.5s;
  animation-timing-function: ease
}

.duration-300 {
  animation-duration: 0.3s;
}
