/**
 * @molecule News Item
 * @section Navigation > Cards and boxes
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
 *  <a href="#" class="news-item">
 *    <div class="news-item__content">
 *      <div class="news-item__content__title">In memory of Lennart Nilsson</div>
 *      <div class="news-item__content__text">Extended until the 14th of may!</div>
 *      <div class="news-item__content__date">11:50 AM - 22 Mar 2017</div>
 *    </div>
 *    <div class="news-item__image">
 *      <img src="./src/images/small-news.png" alt="">
 *    </div>
 *  </a>
 */

.news-item {
  @include rem(margin-bottom, 30px);
  font-family: $secondary-font-family;
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  // align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 120px;
  background: $neutral-grey;
  box-shadow: 15px 15px 0 0 #343a40;
  border-left: 5px solid $neutral-grey-5 ;
  position: relative;
  transition: all .3s ease-in-out;

  @include breakpoint('palm', 'small') {
    min-height: 0;
    @include rem(margin-bottom, 16px);
    box-shadow: 10px 10px 0 0 #343a40;
  }
  &:hover {
    transform: translate(10px, 10px);
    box-shadow: 10px 10px 0 0 #343a40;
  }

  .section & {
    background: $brand-primary-text;
  }
  #news-list &{
     box-shadow: 15px 15px 0 0 #CED4DA;
    @include breakpoint('palm', 'small') {
       box-shadow: 10px 10px 0 0 #CED4DA;
     }
     &:hover {
       box-shadow: 10px 10px 0 0 #CED4DA;
     }
   }
  &__content {
    @include theme('text', 'darkest');
    @include rem(padding,21px 24px 30px);
    padding-right: 190px;
    max-width: 100%;
    min-height: 160px;

    @include breakpoint('palm', 'large') {
      padding-right: 0;
    }
    @include breakpoint('palm', 'small') {
      @include rem(padding,23px 14px 30px);
      max-width: 100%;
    }

    &__title {
      @include text-truncate;
      font-weight: $font-weight-bold;
    }

    &__date {
      @include font('xi');
      @include rem(margin-top, units(.5));
      color: $neutral-grey-5;
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 160px;
    max-width:160px;
    overflow:hidden;
    @include breakpoint('palm', 'large') {
      display: none;
    }

    img {
      display: block;
      height: 100%;
    }


  }
}
