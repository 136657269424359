/**
 * @molecule Card
 * @section Navigation > Cards and boxes
 * @description
 *  Card component
 * @modifiers
 *  .card--flush remove right margin
 *  .card--small smaller card
 *  .card--large larger card
 * @markup
 *  <div class="card card--large">
 *   <div class="card__content">
 *      <p class="card__content__title">Charlotte Gyllenhammar
 *      <br><i>Wild encounters</i></p>
 *      <p class="small">11 March — 11 June, 2017</p>
 *    </div>
 *  </div>
 */


.card {
  @include rem(margin-right, units(1));
  min-width: 278px;
  height: 212px;
  display: inline-block;
  background-position: center;
  background-size: cover;

  @include breakpoint('palm', 'small') {
    @include rem(margin-top, units(1));
  }

  &--flush {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &--large {
    width: 100%;
    height: 420px;
    .row & {
      width: 100%;
    }
  }

  &--small {
    width: 100%;
    min-width: 100%;
    height: 212px;
    .row & {
      width: 100%;
    }
  }

  &--activity {
    width: 100%;
    min-width: 100%;
  }

  &--video {
    @include rem(margin-bottom, units(1));
    min-width: 100%;
    transition: all .2s ease-in-out;
    transform-style: preserve-3d;
    position: relative;

    &:hover {
      position: relative;
      transform: scale(1.1);
      z-index: 5;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75px;
      height: 75px;
      background-image: url('~images/play.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transform: translate(-50%, -50%);
      transition: all .2s ease-in-out;
      opacity: 0;
    }
  }

  &__content_link{
    cursor: pointer;
  }
  &__content {
    height: 100%;
    display: flex;
    // justify-content: flex-end;
    // align-items: flex-start;
    @include color('brand-primary');
    @include rem(padding, units(1.5));
    background: rgba($neutral-grey-9, .25);
    max-width: 100%;

    p {
      margin: 0;
      padding: 0;
      @include font('ny', 1.2);
      font-family: $secondary-font-family;

      &.date {
        @include font('xi', 1.2);
      }
    }

    &__title {
      @include font('my', 1.2);
      max-width: 100%;
    }
  }

  &__bottom {
    display:flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 100%;
  }
}
