.c-wistia-video {
  &__holder {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;
	width:100%;
  }
  &__video {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
    height: auto!important;
    width:100%!important;
  }


  &:not(.has-video-controls) {
	.w-bottom-bar, .w-bottom-bar-upper {
	  display: none !important;
	}
  }
  &--exhibition{
  .c-wistia-video__holder {
	  position: absolute;
	  left: 50%;
	  top: 0;
	  bottom: 0;
	  padding: 0;
	  height: auto;
	  transform:translateX(-50%);
	  min-height:100%;
	  min-width:100%;
	}
	video {
	  object-fit: cover !important;
	}
	
    .w-chrome{
      min-height:100%;
    }
  }
  &--hero {
	.c-wistia-video__holder {
	  position: absolute;
	  left: 0;
	  right:0;
	  top: 0;
	  bottom: 0;
	  padding: 0;
	  width:100%;
	  height: 100%;
	}
	video {
	  object-fit: cover !important;
	}
    .w-chrome{
      min-height:100%;
    }
  }
}