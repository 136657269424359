.product-item {
  @include rem(padding-bottom, units(2));
  max-width: 100%;
  position: relative;

  &-container {
    max-width: 100%;
    width: 250px;

    @include breakpoint('palm', 'small') {
      width: 100%;
    }
  }

  &__image {
    @include rem(padding, units(1));
    @include rem(height, 320px);
    text-align: center;
    background: $neutral-grey-2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include breakpoint('palm', 'small') {
      height: auto;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 320px;
      height: auto;
      width: auto;
      border: 4px solid #000;

      @include breakpoint('palm', 'small') {
        max-height: unset;
      }

      .product_cat-editions &,
      .product_cat-posters & {
        border: 4px solid #000;
        box-shadow: inset 0 2px 6px 0 rgba(0,0,0,0.50);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      transition: all .3s ease-in;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      background-color: rgba(0, 0, 0, .5);
    }

    &:hover {
      &:after {
        opacity: 1;
        visibility: visible;
      }

      .product-item__image__buttons {
        visibility: visible;
        opacity: 1;
      }
    }

    &__buttons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: all .3s ease-in;
      z-index: 1;
      width: 100%;

      .added_to_cart {
        @include theme('text', 'brand-primary');
        font-family: $secondary-font-family;
        display: block !important;
      }

      .ajax_add_to_cart {
        background: $green-button-bg;
      }
    }
  }

  &__content {
    @include rem(padding-top, units(1));

    &__title {
      @include font('my');
      @include text-truncate;
      line-height: 1.5;
      margin: 0;
      padding: 0;

      .swiper-slide & {
        @include rem(max-width, 220px);
      }

      .theme-dark & {
        a {
          color: #ffffff;
        }
      }

      a {
        text-decoration: none;
      }
    }

    &__title0 {
      @include font('my');
      @include text-truncate;
      line-height: 1.5;
      margin: 0;
      padding: 0;

      .swiper-slide & {
        // @include rem(max-width, 220px);
      }

      .theme-dark & {
        a {
          color: #ffffff;
        }
      }

      a {
        text-decoration: none;
      }
    }

    &__type {
      @include font('my');
      line-height: 1.5;
      font-style: italic;
      margin: 0;
      padding: 0;
      font-size: .45rem;
      color: #666;
      .theme-dark & {
        a {
          color: #ffffff;
        }
      }

      a {
        text-decoration: none;
      }
    }

    &__tags {
      @include rem(padding-top, units(.5));

      &__fav {
        @include rem(margin-right, units(.5));
        display: inline-block;
        width: 19px;
        height: 19px;
        background-image: url('~images/icons-new/heart.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        vertical-align: middle;
        transition: all .3s ease-in-out;

        &.favorite-selected {
          background-image: url('~images/icons-new/heart-filled.svg');
        }

      }
    }
  }
}
