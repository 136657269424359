$modular-scale: (
  'scale': (
    'alpha':    64px,
    'beta':     58px,
    'gamma':    52px,
    'delta':    48px,
    'epsilon':  40px,
    'zeta':     36px,
    'eta':      32px,
    'theta':    29px,
    'jota':     24px,
    'kappa':    22px,
    'lambda':   20px,
    'my':       18px,
    'ny':       16px,
    'xi':       14px,
    'omikron':  12px,
    'pi':       10px
  )
);

@include if-theme('fgny') {
  $modular-scale: (
    'scale': (
      'alpha': 64px,
      'beta': 58px,
      'gamma': 52px,
      'delta': 48px,
      'epsilon': 40px,
      'zeta': 36px,
      'eta': 32px,
      'theta': 29px,
      'jota': 24px,
      'kappa': 22px,
      'lambda': 20px,
      'my': 20px,
      'ny': 20px,
      'xi': 20px,
      'omikron': 15px,
      'pi': 15px
    )
  ) !global;
}
@include modular-scale-init($modular-scale);
