.tag-block {
  @include font('pi');
  @include rem(letter-spacing, 1px);
  @include rem(padding,2px 10px);
  font-family: $secondary-font-family;
  background: $neutral-grey-3;
  color: $neutral-grey-8;
  display: inline-block;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  vertical-align: middle;
  
  .section--dark & {
    color: $brand-primary-text;
    background: $neutral-grey-8;
  }
  &--dark {
    color: $brand-primary-text;
    background: $neutral-grey-8;
  }
  &--inverted {
    background: $neutral-grey-3;
    color: $neutral-grey-8;
  }

  &--secondary {
    background: $brand-primary-text;
    border: 1px solid #DDDDDD;
    color: $neutral-grey-8;
  }

  span {
    cursor: pointer;
    @include rem(margin-left, units(1.5));
    @include rem(padding, units(.5) 0);
  }
}
