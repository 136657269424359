@include if-theme() {

  // h1: 40px
  // h2: 32px
  // h3: 24px
  // h4: 16px

  @font-face {
    font-family: 'Karla';
    src: url('~fonts/karla-regular-webfont.eot');
    src: url('~fonts/karla-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('~fonts/karla-regular-webfont.woff2') format('woff2'),
    url('~fonts/karla-regular-webfont.woff') format('woff'),
    url('~fonts/karla-regular-webfont.ttf') format('truetype'),
    url('~fonts/karla-regular-webfont.svg#karlaregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-weight: $font-weight-medium;
    font-family: $base-font-family;
  }

  h1,
  .h1 {
    @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('theta'), font('epsilon'));
    @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('theta')), baseline(font('epsilon')));
  }

  h2,
  .h2 {
    @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('kappa'), font('eta'));
    @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('kappa')), baseline(font('eta')));
  }

  h3,
  .h3 {
    @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('lambda'), font('jota'));
    @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('lambda')), baseline(font('jota')));
  }

  h4,
  .h4 {
    @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('jota'), font('zeta'));
    @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('jota')), baseline(font('zeta')));
  }

  h5,
  .h5 {
    @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('kappa'), font('eta'));
    @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('kappa')), baseline(font('eta')));
  }

  h6,
  .h6 {
    @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('lambda'), font('jota'));
    @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('lambda')), baseline(font('jota')));
  }

  .base-font,
  .base-font > p {
    font-family: $base-font-family;
  }

  .secondary-font {
    font-family: $secondary-font-family;
  }

  .truncate {
    @include text-truncate;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .font-weight-light { font-weight: $font-weight-light; }
  .font-weight-medium { font-weight: $font-weight-medium; }
  .font-weight-semibold { font-weight: $font-weight-semibold; }
  .font-weight-bold { font-weight: $font-weight-bold; }

  .heading {
    &--alt {
      font-family: $secondary-font-family;
    }

    &--small {
      @include font('ny');
      padding: 0;
      margin: 0;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-family: $secondary-font-family;
    }
  }

  a {
    text-decoration: none;
    color: $neutral-grey-9;
  }

  p {
    @include font('ny', 2);
    // @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('my'), font('lambda'));
    @include rem(margin-bottom, units(2));

    &.small {
      @include font('ny', 2);
      // @include fluid-type(font-size, breakpoint('small'), breakpoint('wide'), font('xi'), font('ny'));
      font-family: $secondary-font-family;
    }

    &.lead, .lead & {
      @include font('jota');
      font-family: $secondary-font-family;
    }

    a {
      text-decoration: underline;
    }

    &.error {
      color: $utility-pomegranate;
    }
  }

  .no-decoration {
    text-decoration: none;
  }

  .p-small {
    p {
      @include font('ny', 2);
      font-family: $secondary-font-family;
    }
  }

  blockquote {
    position: relative;

    &:before {
      content: '';
      display: block;
      background-color: $neutral-grey-5;
      position: absolute;
      top: 8px;
      height: calc(100% - 19px);
      left: -30px;
      width: 5px;

      @include breakpoint('palm', 'medium') {
        top: 10px;
        height: calc(100% - 18px);
      }

      @media(max-width: 880px) {
        left: -14px;
      }

      @include breakpoint('palm', 'small') {
        top: 8px;
        height: calc(100% - 14px);
      }
    }

    &:not(.big) {
      color: $neutral-grey-6;
    }

    &.big {
      + p {
        @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('jota'), font('zeta'));
        @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('jota')), baseline(font('zeta')));
      }

      @include breakpoint('palm', 'medium') {
        padding: 0 10px;
      }

      &:before {
        background-color: $neutral-grey-7;
        left: -50px;

        @include breakpoint('palm', 'medium') {
          top: 10px;
          height: calc(100% - 18px);
          left: -7px;
        }

        @include breakpoint('palm', 'small') {
          top: 7px;
          height: calc(100% - 13px);
        }
      }
    }
  }

  blockquote {
    &.big p {
      @include fluid-type(font-size, breakpoint('small'), breakpoint('large'), font('jota'), font('zeta'));
      @include fluid-type(line-height, breakpoint('small'), breakpoint('large'), baseline(font('jota')), baseline(font('zeta')));
      display: inline-block;
    }
  }

  .t-text--note{
    color:#F22613;
  }

}
