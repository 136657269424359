@mixin select($selector: select, $border-bottom: 1px solid $neutral-grey-9) {
  background-image: url('~images/arrow_down.png');
  background-repeat: no-repeat;
  background-position: right;

  @include if-theme() {
    font-family: $secondary-font-family;
  }

  &.woocommerce-validated {
    #{$selector} {
      border-color: $neutral-grey-9;
    }
  }

  #{$selector} {
    &.placeholder {
      color: #495057;
    }

    background: transparent;
    border: none;
    border-radius: 0;
    outline: 0;
    width: 100%;
    height: 55px;
    // @include breakpoint('palm', 'small') {
    //   height: 45px;
    // }
    overflow: hidden;
    border-bottom: $border-bottom;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      @include disableAutoFill;
    }

    .woocommerce-validated & {
      border-color: $neutral-grey-9;
    }


  }

}


.select {
  @include select;
}


.react-datepicker__input-container {
  @include select("button");
  & > input,
  & > button {
    cursor: default;
    padding: 0;
    text-align: left;
  }
}
