 .modal {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: -1;
  visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  pointer-events: auto;
  outline: 0;


  &--mini {
    width: 600px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;

    .modal__content {
      min-height: auto;
      box-shadow: 0 0 2px 0 rgba(122,245,210,1);
      border-radius: 4px;
    }

    @include breakpoint('palm', 'small') {
      height: 100%;
      width: 100%;
    }
  }


   &--slim {
    width: 450px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;

    .ReactModal__Overlay & {
      width: 300px;

      @include breakpoint('palm', 'small') {
        width: 100%;
      }
    }

    .modal__content {
      min-height: auto;
      box-shadow: 0 0 2px 0 rgba(122,245,210,1);
      border-radius: 4px;
    }

    @include breakpoint('palm', 'small') {
      height: 100%;
      width: 100%;
    }
  }


  &__content {
    background: $brand-primary-text;

    @include if-theme('fgny') {
      background: $bg-color;
      border: none;
    }
    width: 100%;
    height: 100%;

    // @include rem(padding, 0 units(1));
    overflow: auto;
    min-height: auto;
    -webkit-overflow-scrolling: touch;

    //@media screen and (max-width: 767px) {
    //  min-height: 100vh;
    //}

    &__header {
      //@include rem(padding, units(3) 0);
      padding: 45px 0;
      // position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include breakpoint('palm', 'small') {
        padding: 20px 10px 11px;
      }
    }
  }

  &-effect-1 {
    .modal__content {
      transform: scale(.7);
      opacity: 0;
      transition: all .3s;
    }
  }

  &-effect-2 {
    .modal__content {
      transform: scale(0) rotate(720deg);
      opacity: 0;
      transition: all .5s;
    }
  }

  &-effect-3 {
    perspective: 1300px;

    .modal__content {
      transform-style: preserve-3d;
      transform: rotateX(-60deg);
      transform-origin: 50% 0;
      opacity: 0;
      transition: all .5s;
    }
  }

  &-show {
    visibility: visible;
    z-index: 998;
    &.modal-effect-1 {
      .modal__content {
        transform: scale(1);
        opacity: 1;
      }
    }

    &.modal-effect-2 {
      .modal__content {
        transform: scale(1) rotate(0deg);
        opacity: 1;
      }
    }

    &.modal-effect-3 {
      .modal__content {
        transform: rotateX(0deg);
        opacity: 1;
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 988;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  transition: all .3s;
  pointer-events: auto;
}

.modal-show ~ .modal-overlay {
  opacity: 1;
  visibility: visible;
}

@include breakpoint('small', 'large') {
  .modal {
    &__content {
      @include rem(padding, 0 units(2));
      width: 100%;
    }
  }
}

.ReactModal__Overlay {
 & > div {
   border-radius: 0 !important;
 }
}
