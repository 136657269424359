.input-radio,
.radio {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  + .radio-label,
  &.shipping_method + label
  {
    @include rem(padding-left, units(2));
    // @include rem(height, 20px);
    @include font('ny');
    @include rem(line-height, 20px);
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
  }

  &:checked + .radio-label,
  &.shipping_method:checked + label {
    &:before {
      background-position: 0 -20px;
    }
  }

  &:checked ~ .payment_box {
    display: block !important;
  }

  ~ .payment_box {
    display: none !important;
  }

  &-label,
  &.shipping_method + label {
    @include rem(padding-bottom, units(.5));
    font-family: $secondary-font-family;
    -webkit-touch-callout: none;
    user-select: none;

    &:before {
      @include rem(height, 20px);
      @include rem(width, 20px);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-image: url('~images/radio.png');
      background-repeat: no-repeat;
    }
  }

  &-container {
    // @include rem(margin-bottom, units(1));
  }
}
