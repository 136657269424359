.footer {
  @include rem(padding, 46px 0);
  background: $neutral-grey-2;
  font-family: $secondary-font-family;

  @include breakpoint('palm', 'small') {
	text-align: center;
	@include rem(padding, units(3) 0);
  }
  .select select {
	@include rem(margin-top, -12px);
	padding-top: 2px;
	@include breakpoint('palm', 'small') {
	  margin-top: 0;
	  padding-top: 0;
	}
  }
  .text-input {
	margin: 0 auto;
	@include breakpoint('small') {
	  width: 100%;
	}
  }

  &__logo {
	@include breakpoint('palm', 'small') {
	  @include rem(margin-bottom, units(2));
	}
  }

  &__title {
	@include font('lambda', .78);
	font-family: $base-font-family;
	@include rem(margin-bottom, units(.78));
	@include rem(padding-bottom, 4px);
	@include rem(padding-top, 12px);
	position: relative;
	display: inline-block;
	&:after {
	  content: '';
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  height: 1px;
	  width: 30px;
	  background: #000;
	  @include breakpoint('palm', 'small') {
		left: 50%;
		transform: translateX(-50%);
	  }
	}
  }

  &__list {
	@include rem(padding, 0 0 35px 0);
	@include font('ny');
	list-style: none;
	margin: 0;
	@include breakpoint('palm', 'small') {
	  @include rem(padding, 0 0 units(2) 0);
	}
  }

  &__rights {
	@include rem(margin-top, 22px);
	@include font('omikron');
	color: $neutral-grey-6;
	@include breakpoint('palm', 'small') {
	  @include rem(margin-top, units(2));
	}
  }
  .container {
	@include rem(max-width, 1166px);
  }
  @media (min-width: 1214px) {
	.row {
	  margin: 0;

	}
	.col-sm-3 {
	  padding: 0;

	  @include rem(padding-right, units($spacing));
	  &:last-child {
		padding-right: 0;
	  }
	}

  }
}

.footer-companies {
  @include rem(padding, units(3) 0);
  overflow: visible;

	  @include breakpoint('palm', 'small') {
	   @include rem(padding, 20px 0 16px);
	  }
  &::-webkit-scrollbar {
	display: none; // Safari and Chrome
  }

  &__list {
	text-align: center;
	overflow-y: hidden;
	overflow-x: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	max-width: 100%;
	width: 100%;

  @include breakpoint('palm', 'small') {
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  }

	&::-webkit-scrollbar {
	  display: none; // Safari and Chrome
	}
&__img {
	    max-width: 100%;
     @include rem(max-height, 100px);
    width: auto;
    height: auto;
    display: block;
    margin:0 auto;
}
	&__item {
	  @include rem(margin-right, units(3));
	  @include rem(max-height, 100px);
	  height: 100%;
	  display: inline-block;
	  max-width: 100%;
	  @include breakpoint('palm', 'small') {
	  height:auto;
 display:block;
 text-align:center;
 margin:0;
 flex:0 0 50%;
max-width:50%;
@include rem(margin-bottom, 4px);
  }
	}
  }
}

.footer-hero {
  @include rem(max-height, 450px);
  @include rem(padding-top, units(4));
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  overflow: hidden;

  @include breakpoint('palm', 'small') {
	@include rem(max-height, 300px);

  }

  &__content {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to top, rgba(32, 36, 40, 0.00) 0%, $neutral-grey-9 85%);
	text-align: center;

	&__title {
	  @include rem(margin-top, units(4));
	}

	a {
	  color: $brand-primary-text;
	}
  }
}

.footer-terms {
  // @include rem(height, 340px);
  @include rem(margin-top, units(1));
  width: 100%;
  background-image: url('~images/footer-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;

  @include breakpoint('palm', 'small') {
	height: auto;
  }

  .container {
	height: 100%;
  }

  &__content {
	@include rem(padding, units(4) 0);
	height: 100%;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;

	@include breakpoint('palm', 'small') {
	  @include rem(padding, units(2) 0);
	  align-items: center;
	}

	&__links {
	  @include rem(margin-top, units(1));
	  @include breakpoint('palm', 'small') {
		text-align: center;
	  }

	  ul {
		list-style: none;

		li {
		  @include rem(padding-right, units(1));
		  display: inline-block;

		  @include breakpoint('palm', 'small') {
			padding-right: 0;
			display: block;
		  }

		  a {
			@include theme('text', 'brand-primary');
			font-family: $secondary-font-family;
		  }
		}
	  }
	}
  }

}

// Cookie message
body .pea_cook_wrapper {
  padding: 21px;
  border-radius: 0;
  &.pea_cook_bottomcenter {
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
  }
  button.pea_cook_btn {
	@extend .btn--secondary;
	@extend .btn--round;
	text-shadow: none;
	width: auto;
	border: none;
  }
}

.footer-col {
  order: 0;

}

@include breakpoint( 'small') {
  .footer-col--1 {
	order: 1;
  }

  .footer-col--2 {
	order: 3;
  }

  .footer-col--3 {
	order: 4;
  }
}
.footer-col--trygg {
  order: 2;

  @include breakpoint('palm', 'small') {
	order: 0;
  }
}

.footer-col--loc {
  order: 10;
}