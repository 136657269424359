/**
 * @molecule Partner logo
 * @section Global > Partner Logo
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
    <a target="_blank" href="{{item.partner_link}}" class="partner-logo">
      <img class="partner-logo__image" src="{{TimberImage(item.partner_logo )}}">
    </a>
 */

.partner-logo {
  @include rem(margin-top, units(2));
  display: inline-block;
  opacity: .4;
  transition: opacity .3s ease;
  cursor: pointer;

  @include breakpoint('palm', 'small') {
    width: 100%;
    text-align: center;
  }

  &:hover {
    opacity: 1;
  }

  &__image {
    width: auto;
  }
}
