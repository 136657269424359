$base-font-size: 20px;
$base-font-size-mobile: 20px;
$base-line-height: 1.75;
$base-line-height-px: $base-font-size * $base-line-height;
$bg-color: white;
@include if-theme('fgny') {
  $bg-color: #f5f2f0 !global;
}

@include if-theme('fgny') {
  $base-font-size: 20px !global;
  $base-line-height: 1.15 !global;
  $base-line-height-px: $base-font-size * $base-line-height !global;
}

/**
 * Spacing
 */
$base-spacing-unit: $base-line-height-px;
$half-spacing-unit: $base-spacing-unit / 2;
@include if-theme('fgny') {
  $base-spacing-unit: $base-line-height-px;
  $half-spacing-unit: $base-spacing-unit / 2;
}

$base-border-radius: 25px;
@include if-theme('fgny') {
  $base-border-radius: 0 !global;
}

/**
 * Font
 */
$base-font-family: 'Minion Pro', sans serif !default;
$secondary-font-family: 'Karla', sans serif !default;

@include if-theme('fgny') {
  $base-font-family: minion-pro, serif !global;
  $secondary-font-family: apotek-comp, sans-serif !global;
}

$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

@include if-theme('fgny') {
  $font-weight-light: normal !global;
  $font-weight-medium: normal !global;
  $font-weight-semibold: normal !global;
  $font-weight-bold: normal !global;
}

$green-button-bg: #26c281;

/**
 * Rem
 */
$rem-baseline: $base-line-height-px;
@include if-theme('fgny') {
  //$rem-baseline: $base-line-height-px * 1.2 !global;
}
$rem-fallback: true;
$rem-px-only: false;
