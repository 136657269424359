.cart-box {

  .select {

    background: transparent;

    @include rem(padding, units(.5) 0);
    @include fgny-b1;
    min-height: 46px;

    &::placeholder {
      @include if-theme('fgny') {
        color: #5A5A5A;
        opacity: 1;
      }
    }

    border: 0;
    outline: 0;
    -webkit-border-radius: 0;
    appearance: none;
    border-radius: 0;
    border-bottom: $input-border-style;
    caret-color: $neutral-grey-9;

    @include text-truncate;

    // padding: 0 20px 0 0;

  }
}