$header-height: 150px;
$header-height-palm: 70px;

.site-header {
  background: $brand-primary-text;
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);

  .container {

    @include rem(max-width, 1217px);

    @include breakpoint('xmedium') {
      @include rem(padding,0 40px);
    }
  }
  &__logo{

    @include breakpoint('xmedium') {
      @include rem(padding,7px 0 0);
    }
  }
  @include breakpoint('palm','xmedium') {
    .col-md-3 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  @include breakpoint('palm', 'xmedium') {
    // position: fixed;

    .container {
      padding: 0;
    }
  }

  &.scrolled {
    position: fixed;
    background: $brand-primary-text;
    .site-header__links__list__item__link {
      color: $neutral-grey-9;
    }
    @include breakpoint('xmedium') {
      .site-header__links__list,
      .site-header__right,
      .site-header__logo {
        @include rem(padding, 0);
      }
      .site-header__logo {
        @include rem(margin-right, 30px);
      }

    }
  }

  &--transparent {
    @include breakpoint('xmedium') {
      background: linear-gradient(0deg, rgba(0, 0, 0, .00) 0%, rgba(0, 0, 0, .40) 100%);

      .site-header__links__list__item__link {
        @include color('brand-primary');
      }
    }
  }

  &__subnav {
    @include font('eta');
    @include rem(padding, units(1));
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $brand-primary-text;
    height: $header-height-palm;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
    transition: all .5s ease-in-out;
    z-index: 12;

    @include breakpoint('xmedium') {
      display: none;
    }

    @media (min-width: 1214px) {
      .container{

        @include rem(padding-right, 56);
      }
    }
    .subnav-open & {
      transform: translateX(0);
    }

    &__title {
      margin: 0;
      padding: 0;
      color: $neutral-grey-9;
    }


    &__close {
      position: relative;
      width: 21px;
      display: block;
      height: $header-height-palm;

      span {
        position: absolute;
        background-color: transparent;
        top: 50%;
        bottom: auto;
        transition: background-color .2s;

        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 21px;
          height: 1px;
          background-color: $neutral-grey-8;
          top: 0;
          left: 0;
          backface-visibility: hidden;
          transition: transform .2s;
        }

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__logo {

    &__desk {
      display: block;
      @include breakpoint('palm', 'xmedium') {
        display: none;
      }

      .scrolled & {
        display: none;
      }
    }

    &__palm {
      display: none;

      @include breakpoint('palm', 'xmedium') {
        display: block;
      }

      .scrolled & {
        display: block;
      }

    }
  }

  &__links {
    height: 100%;
    display: flex;
    align-items: center;
    transition: all .5s ease-in-out;

    @include breakpoint('palm', 'xmedium') {
      position: absolute;
      top: $header-height-palm;
      left: 0;
      width: 100%;
      height: calc(100vh - 70px);
      margin: 0;
      padding: 0;
      background: $neutral-grey-1;
      transform: translateX(100%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &.show-it {
        transform: translateX(0);
      }
    }

    &__bottom {
      @include rem(padding, units(2));
      position: absolute;
      bottom: 15%;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .scrolled & {
        bottom: 0;
      }

      a {
        text-decoration: none;
      }

      @include breakpoint('palm', 'xmedium') {
        display: flex;
      }
    }

    &__list {
      list-style: none;
      height: auto;

      @include breakpoint('xmedium') {
        height: 100%;
      }
      .scrolled & {
        @include breakpoint('xmedium') {
          @include rem(padding-top, 5px);
        }
      }
      &__item {
        @include rem(margin-right, 36px);
        height: auto;
        display: inline-block;
        @include breakpoint('xmedium') {
          height: 100%;
        }
        .scrolled & {
          @include breakpoint('xmedium') {
            @include rem(margin-right, 46px);
          }
        }
        @include breakpoint('palm', 'xmedium') {
          @include rem(padding, units(1));
          display: block;
          width: 100%;
          margin: 0;

          &:last-child {
            border-bottom: none;
          }
        }

        &.active-menu {
          > a {
            @include breakpoint('xmedium') {
              &:after {
                bottom: 0;
                left: 50%;
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(213, 102, 47, 0);
                border-bottom-color: $neutral-grey-1;
                border-width: 15px;
                margin-left: -15px;
              }
            }

            &:before {
              opacity: 1;
            }
          }
        }

        > a {
          @include font('jota');
          height: 100%;
          display: flex;
          vertical-align: middle;
          align-items: center;
          position: relative;
          justify-content: flex-start;

          &:hover {
            &:before {
              opacity: 1;
            }
          }

          .scrolled & {
            @include breakpoint('xmedium') {
              @include font('lambda');
            }
          }

          @include breakpoint('small', 'xmedium') {
            @include font('my');
          }

          @include breakpoint('xmedium') {
            &:before {
              content: '';
              position: absolute;
              top: calc(50% + 10px);
              left: 0;
              width: 100%;
              height: 1px;
              background: #000;
              opacity: 0;
              transition: all 0.3s ease;

              .exhibition & {
                background: $brand-primary-text;
              }
            }
          }

          @include breakpoint('palm', 'xmedium') {
            @include font('kappa');
            position: relative;

            &:after {
              @include rem(right, units(.5));
              @include font('ny');
              position: absolute;
              top: 0;
              font-family: 'FGIcons';
              content: '\e90c';
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.active-menu {
          > ul {
            @include breakpoint('xmedium') {
              display: block;
            }
          }
        }

        &__sub {
          @include rem(padding, units(4) 0 63px 0);
          -webkit-overflow-scrolling: touch;
          list-style: none;
          display: none;
          margin: 0;
          position: absolute;
          left: 0;
          top: $header-height;
          width: 100%;
          background: $neutral-grey-1;
          font-family: $secondary-font-family;

          @include breakpoint('palm', 'xmedium') {
            @include rem(padding-bottom, units(5));
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100vh - 70px);
            margin: 0;
            padding: 0;
            background: $neutral-grey-1;
            display: block;
            transition: all .5s ease-in-out;
            transform: translateX(100%);
            z-index: 10;
            overflow-y: auto;
            pointer-events: auto;

            .container {
              padding: 0;
            }

            .active-menu & {
              transform: translateX(0);
              .container {
                @include rem(padding-bottom, units(6));
              }
            }
          }

          &__title {
            @include font('ny',.6);
            font-family: $base-font-family;
            @include rem(margin-bottom, units(.78));
            @include rem(padding-bottom, units(0.355));
            position: relative;
            display: inline-block;
            color: $neutral-grey-9;

            @include breakpoint('xmedium') {
              @include font('lambda', .78);
              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 30px;
                background: #000;
              }
            }

            @include breakpoint('palm', 'xmedium') {
              // display: none;
              @include rem(padding, 0 units(1));
              text-transform: uppercase;
              background-color: $neutral-grey-3;
              margin: 0;
              width: 100%;
              font-family: $secondary-font-family;
            }
          }

          &__links {
            @include font('ny');
            @include rem(padding, 0 0 46px 0);
            list-style: none;
            margin: 0;

            @include breakpoint('palm', 'xmedium') {
              padding: 0;
            }

            li {
              &:last-child {
                border-bottom: none;
              }
            }

            li > a {
              @include breakpoint('palm', 'xmedium') {
                @include rem(padding, units(.5) units(1));
                @include font('ny');
                display: block;
                width: 100%;
                margin: 0;
                position: relative;


                &:after {
                  @include rem(right, units(.5));
                  @include font('ny');
                  position: absolute;
                  top: 0;
                  font-family: 'FGIcons';
                  content: '\e90c';
                }
              }
            }
          }

          .scrolled & {
            @include breakpoint('xmedium') {
              top: $header-height-palm;
            }
          }

          li {
            width: 100%;
          }
        }
      }
    }
  }

  &__about {
    @include rem(padding-right, units(1));
    display: flex;
    align-items: center;
    transition: all .5s ease-in-out;
    height: 100%;

    @include breakpoint('palm', 'xmedium') {
      display: none;
    }

    .site-header__links__list__item > a {
      @include font('ny');
    }
  }
  &__right__cart{
    @include breakpoint( 'xmedium') {
      @include rem(margin-right, 5px);
    }
  }

  &__right {
    display: flex;
    align-items: center;
    font-family: $secondary-font-family;
    height: 100%;

    @include breakpoint( 'xmedium') {
      @include rem(padding-top, 4px);
    }
    &__list {
      @include rem(margin, units(1) 0 units(1) 16px);
      list-style: none;

      @include breakpoint('palm', 'xmedium') {
        @include rem(margin-left, 16px);
      }
      li {
        @include rem(margin-right, units(1));
        @include font('my');
        display: inline-block;
        &.site-header__right__search{
          @include breakpoint( 'xmedium') {
            @include rem(margin-right, 8px);
          }
        }
        @include breakpoint('palm', 'xmedium') {
          @include rem(margin-right, units(.5));
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__cart {
      .site-header--transparent & {
        @include color('brand-primary');
        @include breakpoint('palm', 'xmedium') {
          @include color('darkest');
        }
      }

      .site-header--transparent.scrolled & {
        @include color('darkest');
      }
    }

    &__btn {
      @include rem(padding, units(1) units(2));
      @include font('ny');
      background: $neutral-grey-2;
      border-radius: 100px;
      transition: all .3s ease;

      @include breakpoint('palm', 'xxsmall') {
        @include rem(padding, units(1) 16px);
      }
      .site-header--transparent & {
        @include color('darkest');
      }
    }

    &__login {
      @include breakpoint('palm', 'xmedium') {
        display: none !important;
      }

      .site-header--transparent & {
        a {
          @include color('brand-primary');
          @include breakpoint('small', 'xmedium') {
            @include color('darkest');
          }
        }
      }

      .site-header--transparent.scrolled & {
        a {
          @include color('darkest');
        }
      }
    }

    &__burger {
      display: none !important;
      position: relative;

      span, span::after, span::before {
        /* this is the hamburger icon */
        position: absolute;
        width: 21px;
        height: 1px;
        background-color: $neutral-grey-8;
      }

      span {
        /* middle line of the hamburger icon */
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
        transition: background-color 0.2s;

        &:after,
        &:before {
          /* top and bottom lines of the hamburger icon */
          content: '';
          top: 0;
          left: 0;
          backface-visibility: hidden;
          transition: transform 0.2s;
        }

        &:before {
          transform: translateY(-7px);
        }

        &:after {
          transform: translateY(7px);
        }

        .site-header-open & {
          background-color: transparent;

          &:before {
            transform: rotate(-45deg);
          }
          &:after {
            transform: rotate(45deg);
          }
        }
      }

      @include breakpoint('palm', 'xmedium') {
        display: inline-block !important;
      }

      a {
        @include rem(padding, units(1));
      }
    }

    &__search {
      @include breakpoint('palm', 'xmedium') {
        display: none !important;
      }

      .icon {
        .exhibition & {
          @include breakpoint('xmedium') {
            color: $brand-primary-text;
          }
        }
        @include breakpoint('small', 'xmedium') {
          @include color('darkest');
        }

        .scrolled & {
          @include breakpoint('small') {
            color: $neutral-grey-9;
          }
        }
      }

      a {
        @include rem(padding, units(.5));
      }
    }
  }

  &__container {
    @include rem(height, $header-height);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    vertical-align: middle;

    @include breakpoint('palm', 'xmedium') {
      @include rem(height, $header-height-palm);
      @include rem(padding, 0 units(1));
    }

    .scrolled & {
      @include rem(height, $header-height-palm);
    }
  }

  &__close {
    position: absolute;
    height: 0;
    width: 100vw;
    z-index: -1;
  }

  &.subnav-open {
    .site-header__close {
      height: 100vh;
    }
  }
}

.site-header__right__cart {
  @include breakpoint('palm', 'xmedium') {
    display: inline-block;
    @include rem(margin-right, 3px);
  }
}
.site-header__links__list__item__sub{

  @include breakpoint( 'xmedium') {
    .row {
      margin: 0;
    }
    .col-md-3{
      padding: 0;
      @include rem(padding-right, 45px);
    }
  }

}
.site-header__links__list{
  @include breakpoint('palm', 'xmedium') {
    width:100%;
    margin-bottom: auto;
  }
}
.site-header__links__bottom{

  @include breakpoint('palm', 'xmedium') {
    position: relative;
    bottom: 10%;
    @supports (-webkit-touch-callout: none) {
      bottom: 15%;
      .scrolled & {
        bottom: 0;
      }
    }
  }
}
.is-lang-en{
  .site-header:not(.scrolled) .site-header__links__list__item{
    @include rem(margin-right,18px);
    &:last-child{
      margin-right: 0;
    }
  }

  .site-header:not(.scrolled) .site-header__links__list{
    @include rem(margin-left,20px);
  }
  .site-header:not(.scrolled)  .site-header__about{
    display: none;
  }
}
.site-header__subnav__back{
  @include breakpoint('palm', 'xmedium') {
    display: block;
    @include rem(margin-bottom,8px);
  }
}