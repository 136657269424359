.arrows {
  display: inline-block;
  vertical-align: middle;

  &--right {
    float:right;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: inline-block;
    cursor: pointer;
    outline: none;
    @include font('lambda');
    @include rem(padding, units(1));

    &.swiper-button-disabled {
      color: #e2e2e2;
      cursor: default;
    }
  }
}
