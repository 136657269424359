$breakpoints-settings: () !default;
$breakpoints: () !default;

@mixin breakpoints-init($settings) {
  $breakpoints-settings: map-merge($breakpoints-settings, $settings) !global;
  $breakpoints: map-get($breakpoints-settings, 'breakpoints') !global;
}

@mixin breakpoint($breakpoint, $breakpoint-max: false) {
  @if $breakpoint-max {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) and map-has-key($breakpoints, $breakpoint-max) {
      $min: map-get($breakpoints, $breakpoint);
      $max: map-get($breakpoints, $breakpoint-max);
      @media (min-width: $min) and (max-width: ($max)) {
        @content;
      }
    } @else {
      @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}` or `#{$breakpoint-max}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
    }
  } @else {
    @if map-has-key($breakpoints, $breakpoint) {
      $min: map-get($breakpoints, $breakpoint);
      @media (min-width: ($min+1)) {
        @content;
      }
    } @else {
      @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
    }
  }
}


@function breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @return map-get($breakpoints, $breakpoint);
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
    + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}
