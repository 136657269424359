.member-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $secondary-font-family;
  border-bottom: 1px solid $neutral-grey-7;
  @include rem(padding-bottom, units(1));
  @include rem(margin, units(2) 0);

  &__container {

  }
}
