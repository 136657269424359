address {
  @include rem(padding, units(1) 0 units(2));
  font-family: $secondary-font-family;
  font-style: normal;
  border: 0;
}

.woocommerce {

  &-cart {
    .woocommerce-cart-form {
      .actions {
        display: flex;
        justify-content: flex-end;

        @include breakpoint('palm', 'small') {
          display: block;
          text-align: right;

          .btn {
            margin-right: 0;
          }
        }
      }
    }
  }

  .woocommerce-checkout {
    .payment_box {
      display: none;
      background-color: #f1f3f5 !important;

      &:before {
        border-bottom-color: #f1f3f5 !important;
      }

      .wc-credit-card-form.wc-payment-form {
        .StripeElement {
          @extend .text-input;
          min-height: auto !important;
          padding: units(1) 0 !important;
          background-color: transparent !important;
          border: 0 !important;
          border-bottom: 1px solid $neutral-grey-7 !important;
          color: $neutral-grey-9 !important;
        }
      }
    }
  }

  span {
    &.onsale {
      @include font('my');
      @include rem(padding, 0);
      @include rem(top, 0);
      @include rem(right, 0);
      @include rem(left, 0);
      border-radius: 0;
      background-color: #c73615;
      min-height: 0;
    }
  }

  form {
    font-family: $secondary-font-family;
  }

  .cart {
    border: 0;
    box-shadow: none;
    width: 100%;
    padding: 0;

    input {
      outline: 0;
    }
  }

  .blockUI.blockOverlay:before, .woocommerce.loader:before {
    @include rem(height, units(2));
    @include rem(width, units(2));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    content: '';
    animation: none;
    background: url('~images/svg-loaders/tail-spin.svg') center center;
    background-size: cover;
    text-align: center;
  }

  form .custom-form-row {
    @include rem(margin, units(.5) 0 units(.5) 0);

    textarea {
      height: auto;
    }

    label {
      @include font('xi');
      line-height: 1;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }
  }

  &-EditAccountForm {
    label {
      @include font('xi');
      line-height: 1;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }

    .input-group {
      @include rem(margin, units(.5) 0 units(.5) 0);
    }
  }

  .widget_shopping_cart {
    //asd
  }

  .widget-title {
    @include rem(margin-bottom, units(1));
    @include rem(padding-bottom, units(.5));
    @include font('ny');
    border-bottom: 1px solid $neutral-grey-4;
    color: #1f2225;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-family: $secondary-font-family;
  }

  .widget_price_filter {
    .price_slider_wrapper {
      @include rem(padding-top, units(1));

      .ui-widget-content {
        height: 5px;

        &.ui-widget-content {
          background: #d8d8d8;
        }

        .ui-slider-range {
          width: 100%;
          cursor: pointer;
          background: $neutral-grey-8;
          border-radius: 5px;
        }

        .ui-slider-handle {
          @include rem(margin-top, -8px);
          @include rem(margin-left, -14px);
          -moz-appearance: none;
          -ms-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background: $brand-primary-text;
          border: 1px solid rgba(0,0,0,0.10);
          box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.10), 0 3px 3px 0 rgba(0,0,0,0.05);
          height: 28px;
          width: 28px;
          border-radius: 100%;
          cursor: pointer;
        }


      }
      .price_slider_amount {
        @include font('ny');
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price_label {
          @include font('ny');
          flex: 1 1 auto;
        }

        .button {
          @include font('ny');
          color: #212;
          background-color: transparent;
          padding: 0;
          outline: 0;
          border: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }

    }
  }

  .widget_product_categories {
    @include rem(padding, units(2) 0);
    font-family: $secondary-font-family;
    .product-categories {
      list-style: none;

      .cat-item {
        // @include rem(padding-left, units(1));
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.current-cat {
          a {
            &:before {
              background-image: url('~images/radio.png');
              background-position: 100% 100%;
            }
          }
        }

        a {
          &:before {
            content: " ";
            width: 16px;
            height: 16px;
            background-image: url('~images/radio.png');
            background-repeat: no-repeat;
            background-size: 100%;
            margin-right: 8px;
            margin-top: 5px;
            display: block;
            float: left;
          }
        }

        .children {
          list-style: none;
        }
      }
    }
  }

  .widget_layered_nav {
    @include rem(padding, units(2) 0);
    font-family: $secondary-font-family;

    .woocommerce-widget-layered-nav-list {
      list-style: none;
      &__item {
        // @include rem(padding-left, units(1));
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:before,
        &:after {
          content: '' !important;
          display: none !important;
        }

        &.chosen {
          a {
            &:hover {
              &:before {
                background-image: url('~images/checkbox-cross.svg');
              }
            }
            &:before {
              background-image: url('~images/checkbox-checked.svg');
            }
          }
        }

        a {
          &:before {
            content: " ";
            width: 16px;
            height: 16px;
            background-image: url('~images/checkbox.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 8px;
            margin-top: 5px;
            display: block;
            float: left;
          }
        }

      }
    }
  }
  #add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment {
    background: transparent;
  }
  #add_payment_method #payment ul.payment_methods li, .woocommerce-cart #payment ul.payment_methods li, .woocommerce-checkout #payment ul.payment_methods li {
    @include rem(margin, units(2) 0);
    padding: 0;
  }

  #add_payment_method #payment ul.payment_methods, .woocommerce-cart #payment ul.payment_methods, .woocommerce-checkout #payment ul.payment_methods {
    border: 0;
    @include rem(margin, units(1) 0);
    padding: 0;
  }
  #payment .terms, .woocommerce #payment .wc-terms-and-conditions, .woocommerce-page #payment .terms, .woocommerce-page #payment .wc-terms-and-conditions {
    padding: 0;
    float: none;

    label {
      line-height: 1.3;
    }
  }
  #add_payment_method #payment div.form-row, .woocommerce-cart #payment div.form-row, .woocommerce-checkout #payment div.form-row {
    padding: 0;
    @include rem(margin, units(1) 0);
  }
  .wc-terms-and-conditions form .form-row label {
  }

  .cart-collaterals, .woocommerce-page .cart-collaterals {
    width: auto;
  }
  .woocommerce-customer-details address {
    border: 0;
    padding: 0;
  }
}


@include breakpoint('palm', 'medium') {
  .woocommerce-variation-add-to-cart.variations_button,
  .simple-product  {
    flex-wrap: wrap;

    .woocommerce-variation-price {
      flex: 1 1 100%;
    }
  }
}
