.notice {
  @include rem(padding, units(2) units(3));
  @include rem(margin, 0 0 units(1));
  text-align: center;
  position: fixed;
  background: $neutral-grey-9;
  color: $brand-primary-text;
  border-top: none;
  list-style: none outside;
  width: 100%;
  word-wrap: break-word;
  top: 0;
  left: 0;
  z-index: 1000;
  font-family: $secondary-font-family;
  @include fgny-b1;
  @include fgny-light;

  a, p {
    color: $brand-primary-text;
  }

  &.slideitout {
    animation: hideNotice 0s ease-in 5s forwards;
    animation-fill-mode: both;
  }

  .wc-forward {
    display: none !important;
  }

  &__close {
    @include rem(right, units(1));
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    cursor: pointer;
  }

  &.error {
    background-color: $utility-pomegranate;
  }

  &.info {
    background-color: $neutral-grey-9;
  }

  &.message {
    background-color: $neutral-grey-9;
  }

  &:before {
    content: '';
  }
}

@keyframes hideNotice {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
