/// Disable auto fill background color
@mixin disableAutoFill {
  -webkit-box-shadow: 0 0 0px 9999px $bg-color inset !important;
  background-color: $bg-color !important;
  background-clip: content-box !important;
}

/// Change background color
@mixin backgroundColorAutoFill($backgroundColor) {
  -webkit-box-shadow: 0 0 0px 9999px $backgroundColor inset !important;
  background-color: $backgroundColor !important;
  background-clip: content-box !important;
}

/// Change text color
@mixin textColorAutoFill($color) {
  -webkit-text-fill-color: $color !important;
}

/// Change background color on hover
@mixin hoverBackgroundColorAutoFill($backgroundColor) {
  &:hover, &:focus {
    -webkit-box-shadow: 0 0 0px 9999px $backgroundColor inset !important;
  }
}

/// Change text color on hover
@mixin hoverTextColorAutoFill($color) {
  &:hover, &:focus {
    -webkit-text-fill-color: $color !important;
  }
}
