/**
 * @molecule Actionbar
 * @section Navigation > Actionbar
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
 *  <div class="actionbar">
 *    <div class="actionbar__content">En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies permettant la réalisation de statistiques de visite.</div>
 *    <a href="#" class="actionbar__close"></a>
 *  </div>
 */

.actionbar {
  @include rem(border-radius, 4px);
  @include rem(padding, units(1));
  @include rem(padding-right, units(6));
  font-family: $secondary-font-family;
  background: $neutral-grey-8;
  color: $brand-primary-text;
  text-align: center;
  position: relative;

  &__close {
    @include rem(width, 70px);
    position: absolute;
    border-left: 1px solid $neutral-grey-6;
    top: 0;
    right: 0;
    height: 100%;
    background: url('~images/icons-new/close_white.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}
