.fotografiska-cart,
.widget_shopping_cart {
  @include rem(top, 140px);
  @include rem(right, units(3));
  @include rem(padding, units(2));
  width: 460px;
  max-width: 100%;
  background: $brand-primary-text;
  box-shadow: 0 12px 22px 0 rgba(0,0,0,0.24);
  font-family: $secondary-font-family;
  position: absolute;
  z-index: 777;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;

  &.active {
    visibility: visible;
    opacity: 1;
  }


  .scrolled & {
    @include breakpoint('small') {
      @include rem(top, 100px);
    }
  }

  @include breakpoint('palm', 'small') {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .mini-cart-btn-close {
    @include breakpoint('small') {
      display: none;
    }
    position: absolute;
    cursor: pointer;
    @include rem(right, units(0));
    @include rem(top, units(0));
    @include rem(padding, units(2) units(2));
  }

  .remove_from_cart_button {
    &:hover {
      background-color: transparent;
    }
  }

  &_content {
    @include rem(padding, units(2) 0);
  }

  &__list {

    &__item {
      @include rem(padding, units(1) 0);
      border-bottom: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__img {
        img {
          width: 35px;
          height: 35px;
        }
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 35px 35px;
        background-position: center;

      }

      &__name {
        display: block;
        @include text-truncate;
        @include rem(padding-left, units(2));
        max-width: 100%;
        width: 100%;
      }

      &__counter {
        display: block;
        // @include rem(width, 115px);
        text-align: right;
        width: 100%;
        @include breakpoint('palm', 'small') {
          // width: 100%;
        }

        &__minus,
        &__plus {
          width: 23px;
          height: 23px;
          background-repeat: no-repeat;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;

          &.disabled {
            opacity: .2;
            pointer-events: none;
          }
        }

        &__value {
          outline: 0;
          -webkit-appearance: none;
          -moz-appearance: textfield;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
          border: 0;
          text-align: center;

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }

        &__minus {
          background-image: url('~images/icons-new/minus.svg');
        }

        &__plus {
          background-image: url('~images/icons-new/plus.svg');
        }
      }
    }
  }
}

.cart-table {
  @include rem(padding, units(2) 0);
  @include rem(margin, units(2) 0 0 0);
  font-family: $secondary-font-family;
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    border-bottom: 1px solid $neutral-grey-9;

    th {
      @include font('xi');
      @include rem(padding, units(1) 0);
      font-weight: 700;
      text-transform: uppercase;
    }

    td {
      vertical-align: middle;
    }
  }

  tbody {
    border-bottom: 1px solid $neutral-grey-9;

    tr {
      border-top: 1px solid rgba($neutral-grey-9, .2);

      &:first-child {
        border-top: 0;
      }
    }

    td {
      @include rem(margin, units(2) 0);
      vertical-align: middle;
    }
  }

  .product-name {
    img {
      @include rem(margin-right, units(1));
      display: block;
    }

    .name {
      max-width: 200px;
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  .product-addon {
    &-label {
      @include rem(margin-bottom, units(0));
      font-weight: $font-weight-bold;
      display: none;
    }
  }

  .product-quantity {
    .quantity {
      margin: 0;
    }
  }

  .product-subtotal {
    text-align: right;
  }

  .product-price-palm {
    display: none;
  }


  @include breakpoint('palm', 'small') {
    .cart_item {
      position: relative;
      display: block;
      padding: 1px 0;
    }

    .product-name {
      @include rem(max-width, 200px);
      @include rem(min-height, 70px);
      position: relative;
      display: flex;

      .name {
        @include text-truncate();
        @include rem(width, 135px);
        position: absolute;
        top: 0;
        left: 2.5rem;
      }

      img {
        @include rem(max-width, 70px);
        display: block;
        height: auto;
        width: auto;
      }
    }

    .product-addon {
      display: block;
      width: 100%;
      min-width: 100%;

      &-label {
        display: block;
      }
    }

    .product-price-palm {
      @include font('ny');
      display: block;
      position: absolute;
      top: 1.9rem;
      left: 3.5rem;
      margin: 0;
    }

    .product-price {
      display: none;
    }

    .product-quantity {
      // position: relative;
      min-width: 100% !important;

      .quantity {
        @include rem(top, units(2));
        position: absolute;
        right: 0;
      }
    }

    .product-subtotal {
      display: block;
      // position: relative;

      .amount {
        position: absolute;
        top: 3rem;
        right: 0;
        font-weight: 700;
      }
    }

    thead {
      border: 0;

      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }
  }

  &-totals {
    text-align: left;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    @include rem(margin, units(2) 0);
    tr {
      @include font('ny');
      border-top: 0;

      // border-top: 1px solid rgba($neutral-grey-9, .2);
      &:first-child {
        border-top: 0;
        // border-bottom: 1px solid rgba($neutral-grey-9, .2);
      }

      &.order-total {
        th, td {
          font-weight: 700;
        }
      }
    }
    th {
      @include rem(padding, units(1) 0);
      width: 25%;
      font-weight: 300;

      @include breakpoint('palm', 'small') {
        width: 50%;
      }
    }
    td {
      border-top: 0;
      @include rem(padding, units(1) 0);
      vertical-align: middle;
      @include breakpoint('palm', 'small') {
        text-align: right;
      }
    }
  }
}

.wc-r24-container {
  &.loading {
    position: relative;
    opacity: .6;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      transform: translate(-50%, -50%);
      background-image: url('~images/svg-loaders/tail-spin.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
}
.mini-cart_items_number{
  @include font('ny');
}
