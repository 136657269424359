
.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }
.mx0 { margin-left:   0; margin-right:  0 }
.my0 { margin-top:    0; margin-bottom: 0 }

.m1  { @include rem(margin, units(1)) }
.mt1 { @include rem(margin-top, units(1)) }
.mr1 { @include rem(margin-right, units(1)) }
.mb1 { @include rem(margin-bottom, units(1)) }
.ml1 { @include rem(margin-left, units(1)) }
.mx1 { @include rem(margin-left, units(1)); @include rem(margin-right, units(1)) }
.my1 { @include rem(margin-top, units(1)); @include rem(margin-bottom, units(1)) }

.m2  { @include rem(margin, units(2)) }
.mt2 { @include rem(margin-top, units(2)) }
.mr2 { @include rem(margin-right, units(2)) }
.mb2 { @include rem(margin-bottom, units(2)) }
.ml2 { @include rem(margin-left, units(2)) }
.mx2 { @include rem(margin-left, units(2)); @include rem(margin-right, units(2)) }
.my2 { @include rem(margin-top, units(2)); @include rem(margin-bottom, units(2)) }

.m3  { @include rem(margin, units(3)) }
.mt3 { @include rem(margin-top, units(3)) }
.mr3 { @include rem(margin-right, units(3)) }
.mb3 { @include rem(margin-bottom, units(3)) }
.ml3 { @include rem(margin-left, units(3)) }
.mx3 { @include rem(margin-left, units(3)); @include rem(margin-right, units(3)) }
.my3 { @include rem(margin-top, units(3)); @include rem(margin-bottom, units(3)) }

.m4  { @include rem(margin, units(4)) }
.mt4 { @include rem(margin-top, units(4)) }
.mr4 { @include rem(margin-right, units(4)) }
.mb4 { @include rem(margin-bottom, units(4)) }
.ml4 { @include rem(margin-left, units(4)) }
.mx4 { @include rem(margin-left, units(4)); @include rem(margin-right, units(4)) }
.my4 { @include rem(margin-top, units(4)); @include rem(margin-bottom, units(4)) }

.m5  { @include rem(margin, units(5)) }
.mt5 { @include rem(margin-top, units(5)) }
.mr5 { @include rem(margin-right, units(5)) }
.mb5 { @include rem(margin-bottom, units(5)) }
.ml5 { @include rem(margin-left, units(5)) }
.mx5 { @include rem(margin-left, units(5)); @include rem(margin-right, units(5)) }
.my5 { @include rem(margin-top, units(5)); @include rem(margin-bottom, units(5)) }

.m6  { @include rem(margin, units(6)) }
.mt6 { @include rem(margin-top, units(6)) }
.mr6 { @include rem(margin-right, units(6)) }
.mb6 { @include rem(margin-bottom, units(6)) }
.ml6 { @include rem(margin-left, units(6)) }
.mx6 { @include rem(margin-left, units(6)); @include rem(margin-right, units(6)) }
.my6 { @include rem(margin-top, units(6)); @include rem(margin-bottom, units(6)) }

.mxn1 { @include rem(margin-left, units(-1)); @include rem(margin-right, units(-1)); }
.mxn2 { @include rem(margin-left, units(-2)); @include rem(margin-right, units(-2)); }
.mxn3 { @include rem(margin-left, units(-3)); @include rem(margin-right, units(-3)); }
.mxn4 { @include rem(margin-left, units(-4)); @include rem(margin-right, units(-4)); }

.ml-auto { margin-left: auto }
.mr-auto { margin-right: auto }
.mx-auto { margin-left: auto; margin-right: auto; }
