.thumb-text-block {
  border:0 solid $brand-primary-text;
  padding: 0;
  background: $neutral-grey-10;
  @include breakpoint('palm', 'medium') {
    @include rem(border-width, 10px 0 0 0);
  }
  @include breakpoint('palm', 'small') {
    @include rem(border-width, 5px 0 0 0);
  }
  &__wrap {
    height: 100%;
    min-height: 240px;
    @include rem(padding-left, 240px);
    position: relative;
    @include breakpoint('palm', 'small') {
      min-height: 110px;
      @include rem(padding-left, 110px);
    }
  }

  &__link--img {
    position: absolute;
    height: 240px;
    width: 240px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    display: block;

    @include breakpoint('palm', 'small') {
      height: 110px;
      width: 110px;
      top:0;
      transform:none;
    }
  }
  &__img{
    max-width: 100%;
  }
 &__link{
   color:inherit;
 }
&__ttl{
  color:$brand-primary-text;
  @include font('jota');
  line-height: (32/24);
  font-family:$base-font-family;
  font-weight: 400;
  @include rem(margin, 0 0 9px);
  max-width:378px;

  @include breakpoint('palm', 'small') {
    @include font('lambda');
    line-height: (28/20);
    max-width:100%;
    @include rem(margin, 0 0 2px);
  }
}
  &__content {
    @include rem(padding, 46px 40px  40px 40px);

    @include breakpoint('palm', 'small') {
      @include font('omikron');
      line-height: (20px);
      @include rem(padding, 15px 15px  25px 15px);
    }
  }
}
