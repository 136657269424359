.checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  + .checkbox-label {
    @include if-theme() {
      @include rem(height, 20px);
      @include font('ny');
      @include rem(line-height, 20px);
    }

    padding-left: 30px;
    line-height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
  }

  &:checked + .checkbox-label {
    // background-position: 0 -20px;
    background-image: url('~images/checkbox-checked.svg');
  }

  &-label {
    @include if-theme() {
      font-family: $secondary-font-family;
    }
    @include fgny-b1;
    background-image: url('~images/checkbox.svg');
    -webkit-touch-callout: none;
    user-select: none;
  }

  &-container {
    @include rem(margin-bottom, units(1));
  }
}
