.tickets-nav {
  overflow: hidden;
  @include rem(padding, units(1) 0);
  .btn {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

.tickets-footer-nav {
  overflow: hidden;
  @include rem(padding, units(1) 0);
  .btn {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
}