.woocommerce-error, .woocommerce-info, .woocommerce-message {
  @include rem(padding, units(1) units(2));
  text-align: center;
  margin: 0;
  position: relative;
  background-color: #f7f6f7;
  color: inherit;
  border-top: none;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
  &:before {
    content: '';
  }
}
