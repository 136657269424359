.section {
  color: $brand-primary-text;
  position: relative;
  font-family: $secondary-font-family;

  &.rev {
    @include breakpoint('palm', 'small') {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &--image {
    @include rem(min-height, 540px);
    display: flex;
    height: 100%;
    width: 100%;
    .mt1{
      @include rem(margin-top, 33px);
    }
    @include breakpoint('palm', 'small') {
      h3, h2 {
        font-size: 24px;
        line-height: (32/24);
      }
      .mt1{
        @include rem(margin-top, 21px);
      }
      h3{
          @include rem(margin-bottom, 7px);
      }
      .text{
        @include rem(margin-right, 3px);
      }
    }
    &.higher {
      @include breakpoint('small') {
        @include rem(min-height, 720px);
      }
    }

    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
      min-height: auto;
    }
    h2{
      @include rem(margin-bottom, 25px); 
      // @include breakpoint('palm', 'small') {
      //   @include rem(margin-bottom, 10px);
      // }
    }

    &.section--exihibition {
      h2 {
        @include rem(margin-bottom, 1px);
        @include breakpoint('palm', 'small') {
          @include rem(margin-bottom, -1px);
        }
      }
    }
  }
  &__image.section__image--lrg {
    display:block;
    @include breakpoint('palm', 'small') {
      display:none;
    }
  }
  &__image.section__image--gallery {
  //    display:none;
    .mt1{
      @include rem(margin-top, 33px);
    }
    h2{
      @include rem(margin-bottom, 0px);

    }
      @include breakpoint('palm', 'small') {
        display:block;
          padding: 0;
        .section__image__image-list:not(:first-child) {
          opacity: 1;
        }
        .section__image__image-list {
          @include rem(min-height, 375px);
          position: relative;
        }
      }
    .swiper-pagination{
     display: none;
      align-items: center;
      justify-content: center;
      @include breakpoint('palm', 'small') {
        display:flex;
      }
    }

    .swiper-pagination-bullet{
      width:10px;
      height:10px;
      border-radius: 0;
      opacity: 1;
      background: transparent;
      display: block;
      padding: 0;
      background: url("~images/circle1.svg");
      background-size: contain;
      background-repeat:no-repeat;
    }
    &.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
      margin:10px;
    }
    .swiper-pagination-bullet-active{
      background: url("~images/circle2.svg");
    }
    &-sml{
    @include breakpoint('palm', 'small') {
        .section__image__image-list {
          @include rem(min-height, 280px);
        }
      }
    }
  }

  &--news {
    @include rem(min-height, 520px);
    display: flex;
    height: 100%;
    .section__content {
      align-self: flex-start;
      @include rem(padding, 46px 73px 70px 80px);
      @include breakpoint('palm', 'large') {
        @include rem(padding, 24px units(1) 44px);
      }
    }
    h2{
      @include rem(margin, 0 0 42px);
      @include breakpoint('palm', 'small') {
        @include rem(margin, 0 0 -7px 0);
      }
    }
    @include breakpoint('palm', 'small') {
      max-width: 100%;
      flex-flow: row wrap;
      .section__image {
        order: 0;
      }

      .section__content {
        order: 1;
      }
    }
  }

  &--video {
    @include rem(min-height, 520px);
    display: flex;
    height: 100%;

    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
    }

    .h2 {
      margin: 0;
      @include breakpoint( 'large') {
        line-height: (36/32);
      }
      @include breakpoint('palm', 'small') {
        font-size: 24px;
        line-height: (32/24);
      }
    }
  }

  &--ticket {
    @include rem(margin, units(0) 0 0 0);
    @include rem(min-height, 730px);

    @include breakpoint('palm', 'small') {
      @include rem(min-height, 100%);
      max-width: 100%;
      display: block;
    }
  }

  &--schedule {
    @include rem(min-height, 520px);
    display: flex;
    height: 100%;
    justify-content: center;

    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
    }
  }

  &--flush {
    @include rem(margin, units(2) 0);
  }

  &--dark {
    .section__content {
      color: $brand-primary-text;
      background: $neutral-grey-9;

      a:not(.book-btn) {
        color: $brand-primary-text;
      }
    }
  }

  &__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 100%;
    max-width: 50%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    img { max-width: 100%; }

    @include breakpoint('palm', 'small') {
      @include rem(padding, units(2));
      @include rem(min-height, 280px);
      max-width: 100%;
      display: flex;
    }

    .section--product & {
      @include rem(padding, units(3) 0);
      background-image: none;
      background-color: $neutral-grey-2;

      @include breakpoint('palm', 'small') {
        @include rem(padding, 0);
        min-height: auto;
      }
    }

    .copyright {
      @include rem(left, units(1));
      position: absolute;
      bottom: 0;
    }

    &__content {
      @include rem(padding, units(2));
      background: $neutral-grey-9;
      box-shadow: 15px 15px 0 0 rgba(52, 58, 64, 1);
    }

    &__image-list {
      background-size: cover;
      background-position: center;
      overflow: hidden;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .4s .2s ease;

      &:not(:first-child) {
        opacity: 0;

        &.active-hover {
          transition: opacity .4s ease;
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }

  &__video {
    width: 100%;
    max-width: 50%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;

    video {
      height: 100%;
      width: 100%;
      // object-fit: cover;
    }

    @include breakpoint('palm', 'small') {
      @include rem(min-height, 350px);
      @include rem(padding, units(2));
      max-width: 100%;
      display: flex;
    }
  }

  &__content {
    @include rem(padding, 44px 80px 63px 80px);
    max-width: 50%;
    width: 100%;
    color: $neutral-grey-9;
    background: $neutral-grey;

    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
    }

    @include breakpoint('palm', 'large') {
      @include rem(padding, 23px units(1) 63px);
    }

    .section--product & {
      background: $brand-primary-text;

      @include breakpoint('large') {
        @include rem(padding, units(4) units(8));
      }
    }

    &--dark {
      color: $brand-primary-text;
      background: $neutral-grey-9;

      a {
        color: $brand-primary-text;
      }
    }

    &__tag {
      @include rem(margin-bottom, units(2));

      @include breakpoint('palm', 'medium') {
        @include rem(margin-bottom, units(1));
      }
    }

    &__info {
      @include rem(margin-left, units(1));
      display: inline-block;

      @include breakpoint('palm', 'medium') {
        display: block;
        @include rem(margin, 21px 0 0);
      }
      &--gallery{

          @include font('xi');
          line-height: (28/14);
      }

    }

    &__header {
      display: flex;
      align-items: center;
    }

    h3 {
      @include rem(margin-bottom, 9px);
    }

    &__scroll {

      &__item {
        @include rem(margin, units(1) 0);
        position: relative;

        &__active {

          &:before {
            content: '';
            width: 5px;
            height: 100%;
            position: absolute;
            left: -20px;
            top: 0;
            background: $neutral-grey-5;
            transition: all .3s ease-in-out;

          }
        }
      }
    }

    .js-image-list-hover {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: calc(100% - 1rem);
        width: 4px;
        background: $brand-primary;
        left: -30px;
        top: 0;
        display: block;
        opacity: 0;
        transition: opacity .3s ease;

        @include breakpoint('small', 'large') {
          left: -15px;
        }

        @include breakpoint('palm', 'small') {
          left: -28px;
        }
      }

      &:hover {
        &::after {
          opacity: .8;
        }
      }
      &.active-hover {
        &::after {
          opacity: .8;

    @include breakpoint('palm', 'small') {
    opacity:0;
        }
      }
      }
    }

    .course_information_content {
      font-family: $secondary-font-family;
    }
  }

  &.section--dark {
    .js-image-list-hover {
      &::after {
        background: $brand-primary-text;
      }
    }
  }

  &__schedule {
    width: 100%;
    max-width: 50%;

    @include breakpoint('palm', 'small') {
      max-width: 100%;
    }
  }

  &__grid {
    max-width: 50%;
    width: 100%;
    color: $neutral-grey-9;

    &.wide {
      max-width: 100%;
      background: $neutral-grey-2;
      border-color: $brand-primary-text;
    }

    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
    }

    &__row {
      display: flex;
      border-bottom: 1px solid $neutral-grey-4;

      &.dark {
        background: $neutral-grey-4;
        // color: $brand-primary-text;
        border-bottom: 1px solid $brand-primary-text;

        @include breakpoint('palm', 'medium') {
          border-bottom: 0;
        }
      }

      &:first-child {
        border-top: 1px soild $neutral-grey-4;
      }

      &:last-child {
        border-bottom: 0;
      }

      .wide & {
        border-bottom: 0;
        display: block;
        border-color: $brand-primary-text;
      }

      @include breakpoint('palm', 'medium') {
        border-bottom: 0;
        display: block;
      }

      &__item {
        // display: flex;
        // flex-flow: row wrap;
        min-height: 230px;
        max-width: 50%;
        width: 100%;
        // border-left: 1px solid $neutral-grey-9;
        border-right: 1px solid $neutral-grey-4;
        @include rem(padding, units(1));

        .dark & {
          // border-left: 1px solid $brand-primary-text;
          border-right: 1px solid $brand-primary-text;

          @include breakpoint('palm', 'medium') {
            border-bottom: 1px solid $brand-primary-text;
          }
        }

        .wide & {
          display: block;
          min-height: auto;
          max-width: 100%;
          width: 100%;
          border-bottom: 1px solid $brand-primary-text;
          border-right: none !important;
          border-color: $brand-primary-text;

          &.title {
            display: flex;
            justify-content: center;
            align-items: center;
            @include rem(padding, units(2));

            h6 {
              margin: 0;
              @include font('jota');

            }
          }
        }

        &:last-child {
          border-right: none;
          border-left: none;
        }

        @include breakpoint('palm', 'medium') {
          display: block;
          min-height: auto;
          max-width: 100%;
          width: 100%;
          border-bottom: 1px solid $neutral-grey-4;
          border-right: none !important;

          &.title {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              margin: 0;
              // @include font('jota');

            }
          }
        }
      }
    }
  }

  &__posters {
    @include rem(padding, units(4));
    background: $neutral-grey-1;
    color: $neutral-grey-9;
    width: 100%;
    max-width: 50%;
    display: flex;
    flex-flow: row wrap;

    img {
      // width: 100%;
      max-width: 100%;
    }

    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
      text-align: center;
      display: flex;
      flex-flow: row nowrap;
          overflow-x: auto;
          overflow-y: hidden;
    }

    &__item {
      display: flex;
      flex-flow: row wrap;

      @include breakpoint('palm', 'medium') {
        flex-flow: row nowrap;
      }

      &__card {
        max-width: 50%;
        width: 100%;

        @include breakpoint('palm', 'medium') {
          flex-flow: row nowrap;
          width: 200px;
          margin-right: units(1);
        }



      }


    }
  }

  &__member {
    max-width: 50%;
    width: 100%;
    color: $neutral-grey-9;
    display: flex;
    flex-flow: row wrap;


    @include breakpoint('palm', 'small') {
      max-width: 100%;
      display: block;
    }

    &__item {
      @include rem(padding, units(1));
      max-width: 50%;
      width: 100%;
      min-height: 360px;
      position: relative;
      background-color: $neutral-grey-1;
      border-right: 1px solid $neutral-grey-9;
      border-bottom: 1px solid $neutral-grey-9;

      &:last-child {
        border-bottom: 0;
        border-right: 0;
      }

      &:nth-child(2) {
        border-right: 0;
      }

      &:nth-child(3) {
        border-bottom: 0;
      }

      @include breakpoint('palm', 'medium') {
        max-width: 100%;
        min-height: auto;
        border-right: 0 !important;
        border-bottom: 1px solid $neutral-grey-9 !important;
        display: flex;
        flex-flow: row wrap;

        &:last-child {
          border-bottom: 0 !important;
        }
      }

      &.dark {
        @include breakpoint('medium') {
          background: $neutral-grey-9;
          color: $brand-primary-text;
        }
      }

      &__content {
        @include breakpoint('palm', 'medium') {
          max-width: 50%;
          width: 100%;
        }
      }

      &__icon {
        @include rem(right, units(1));
        @include rem(bottom, units(1));
        position: absolute;

        img {
          display: block;
          @include rem(width, 120px);
        }

        @include breakpoint('palm', 'medium') {
          position: static;
          max-width: 50%;
          width: 100%;
          justify-content: flex-end;
          align-items: center;
          display: flex;

          img {
            @include rem(width, 80px);
            vertical-align: middle
          }
        }
      }
    }
  }

  &__title {
    // line-height: 72px;
    text-align: center;
    font-family: $secondary-font-family;
  }

  &__date {
    color: $neutral-grey-7;
  }
  &__cta{
    @include font('jota');
    font-family: inherit;
    @include breakpoint('palm', 'small') {
      font-size: inherit;
    }
  }
  &__cta-holder{
    @include rem(margin, -4px 0 24px);

    @include breakpoint('palm', 'small') {
      @include rem(margin, -2px 0 24px);

    }
  }
  &__icon{
    @include rem(margin, 0 20px 0 0);
    display: inline-block;
    vertical-align: top;
  }
  &__block--top {
    @include rem(margin, 0 0 22px 0);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .section__content__tag {
      @include rem(margin, 0 24px 0  0);
      display: block;
    }
    @include breakpoint('palm', 'large') {
      @include font('xi');
      line-height: (28/14);
      @include rem(margin, 0 0 16px 0);
      .icon--xs {
        @include rem(margin, 0px 26px 0 4px);
      }
      .section__tag-holder{
        width:100%;
        @include rem(margin, 0 0 20px  0);
      }
      .section__content__tag {
        @include rem(margin, 0  0);
        display: inline-block;
      }
      h2{
        font-size: 24px;
        line-height: (32/24);
      }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .icon--xs {
        @include rem(margin-top, -2px);
      }
    }

  }

  &--image-info-list{

	@include breakpoint( 'small') {
	  &.section--image.higher {
		min-height: 540px;
	  }
	}
    .pb2{
      @include rem(padding-bottom, 19px);
      @include breakpoint('palm', 'large') {
      @include rem(padding-bottom, 23px);
    }}
    .section__content{
      @include breakpoint( 'large') {
        @include rem(padding, 50px 80px 72px);
      }
      @include breakpoint('palm', 'large') {
        @include rem(padding, 30px units(1) 25px);
      }
    }

    @include breakpoint( 'small') {
      h2 {
        margin-bottom: 0px;
      }
    }
    .tag-block.mb2 {
      @include rem( margin-bottom, 29px);
      @include breakpoint('palm', 'large') {
      @include rem( margin-bottom, 26px);
    }}
  }
  &--left-right{
    .section__content{
      @include breakpoint( 'large') {
        justify-self: flex-start;
        @include rem(padding, 50px 100px 67px 80px);
      }
      @include breakpoint('palm', 'large') {
        @include rem(padding, 30px units(1) 23px);
      }
    }
    .section__content__tag {
      @include breakpoint( 'large') {
      @include rem(margin-bottom, 32px);
    }
      @include breakpoint('palm', 'large') {
        @include rem(margin-bottom, 27px);
      }
    }
    h2{
      @include breakpoint( 'large') {
        @include rem(margin-bottom, 25px);
        line-height: (36/32);
      }
    }
  }
  &--booking {
    h2{
      @include breakpoint( 'large') {
        @include rem(margin-bottom, 25px);
        line-height: (36/32);
      }
    }
  }
  &__video-holder{
  position:absolute;
  left:50%;
  top:50%;    
    transform: translate(-50%, -50%);
  min-width:100%;
  height:100%;
  .c-wistia-video{
  height:100%;
  }
  video{
  object-fit:cover!important;
  }

  .c-wistia-video__video{
    min-height:100%;
    &>div{
    min-height:100%;
    &>div{
    min-height:100%;
    
    }
    }
    [id$="main"]{
    min-height:100%;
    }
  }
  }
  &__video-holder-link{

  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:2;
  }
  &__video{
    overflow:hidden;
  }
}

.so-widget-section_book_a_table_multiple_buttons {
  .mt3 {
    p {
      @include rem(margin-bottom, 12px);
    }
  }
}