/**
 * @atom List
 * @section Navigation > Lists
 * @description
 *  List types
 * @markup
 *  <ul class="list">
 *    <li>Bullet list</li>
 *    <li>Bullet list</li>
 *    <li>Bullet list</li>
 *    <li>Bullet list</li>
 *  </ul>
 *  <ol class="list">
 *    <li>Bullet list</li>
 *    <li>Bullet list</li>
 *    <li>Bullet list</li>
 *    <li>Bullet list</li>
 *  </ol>
 */

.list {
  @include font('lambda');
  @include rem(margin, units(1));

  list-style-position: inside;

  &--nav {
    list-style: none;
  }

  li {

  }
}

.addons {
  @include font('lambda');
  @include rem(margin, units(1) 0);
  list-style: none;

}
