.gallery {
  max-height: 300px;
  width: 100%;
  position: relative;
  display: inline-flex;
  // justify-content: center;
  // flex-flow: column wrap;

  &:after {
    content: '';
    position: absolute;
    width: 150px;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(32,36,40,0.00) 0%, $neutral-grey-9 100%);
  }

  ul {
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: inline;
    }
  }

  img {
    display: inline-block;
    margin-right: 10px;
    height: 100%;
  }
}
