/**
 * @molecule Card-image
 * @section Navigation > Cards and boxes
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
 * <a href="#" class="card-image">
 *   <div class="card-image__content">
 *     <div class="row row--flush bottom-xs">
 *       <div class="col-xs-6">Card-image</div>
 *       <div class="col-xs-6 text-right">Read more &#10230;</div>
 *     </div>
 *   </div>
 * </a>
 */

.card-image {
  @include rem(padding, units(2));
  @include rem(margin-bottom, units(1));
  @include font('ny');
  position: relative;
  min-width: 100%;
  height: 285px;
  display: inline-block;
  background-position: center;
  background-size: 125%;
  font-family: $secondary-font-family;
  color: $brand-primary-text;
  line-height: 1.4;
  transition: all .3s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(-180deg, rgba(0,0, 0, 0) 53%, rgba(0, 0, 0, .60) 88%);
  }

  &:hover {
    background-size: 115%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    z-index: 1;
    position: relative;

    .row {
      width: 100%;
    }

    .text-right {
      margin: 0;
      padding: 0;
    }

  }
}
