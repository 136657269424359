.responsive-video {
  position: relative;
  width: 100%;
  &:after {
    content: "";
    display: block;
    padding-bottom: 56.2%;
    height: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

iframe {
  max-width: 100%;
}
