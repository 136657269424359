.product-list {}

.product-list-filter {

  &__box {
    max-height: 180px;
    height: 100%;
    overflow: overlay;

    &__selection {
      @include rem(margin-top, units(.5));
    }
  }
}
