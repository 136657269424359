@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

@mixin clearfix {
  &:before,
  &:after {
      content: "";
      display: table;
  }
  &:after {
      clear: both;
  }
}


$bgpositionsX: (
  'l': left,
  'c': center,
  'r': right
);

$bgpositionsY: (
  't': top,
  'c': center,
  'b': bottom,
);

@each $short, $name in $bgpositionsX {
  @each $short1, $name1 in $bgpositionsY {
    .bgp-#{$short}-#{$short1} {
      background-position: $name $name1 !important;
    }
  }
}

