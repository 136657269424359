.content {
  @include if-theme() {
    font-family: $secondary-font-family;
  }
  @include if-theme('fgny') {
    font-family: $base-font-family;
  }

  h1,
  h2,
  h3 {
    @include rem(margin-bottom, units(1));
    @include if-theme() {
      font-family: $base-font-family;
    }

    //@include fgny-h3;
    @include fgny-dark;
    @include breakpoint('palm', 'small') {
      @include rem(margin-bottom, units(.5));
    }

    @include if-theme('fgny') {
      margin-top: 60px;
      margin-bottom: 30px;
    }
  }

  h4,
  h5 {
    @include rem(margin-bottom, units(1));
    @include if-theme() {
      font-family: $base-font-family;
    }

    //@include fgny-h5;
    @include fgny-dark;
    @include breakpoint('palm', 'small') {
      @include rem(margin-bottom, units(.5));
    }

    @include if-theme('fgny') {
      margin-top: 30px;
      margin-bottom: 20px;
    }

  }

  h6 {
    @include rem(margin-bottom, units(1));
    @include if-theme() {
      font-family: $base-font-family;
    }

    //@include fgny-h5;
    @include fgny-dark;
    @include breakpoint('palm', 'small') {
      @include rem(margin-bottom, units(.5));
    }

    @include if-theme('fgny') {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

  }

  strong {
    @include fgny-b1;
    @include fgny-dark;
  }


  h1 {
    @include fgny-h4;
    @include fgny-dark;
    @include if-theme('fgny') {
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }

  img {
    // @include rem(margin, units(2) 0);
    max-width: 100%;
    height: auto;
  }

  ul {
    @include rem(padding-left, units(2.5));
    @include rem(padding-bottom, units(2));

    &.dashed {
      list-style-type: '– ';
    }

    li {
      //@include clearfix;
      //display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        //float: left;
      }
      .right {
        float: right;
        margin-left: 1rem;
        align-self: end;
      }
    }

    &.no-bullets {
      list-style: none;
      padding-left: 0;
    }

    li {
      list-style-position: outside;


    }
  }

  p {
    @include if-theme() {
      @include font('ny', 2);
      font-family: $secondary-font-family;
    }
    @include if-theme('fgny') {
      font-family: $base-font-family;
    }
    @include if-theme('fgny') {
      margin-bottom: 1rem;
    }
  }

  .text-width-limit1 {
    max-width: 400px;
  }
  .text-width-limit2 {
    max-width: 600px;
    @include breakpoint('palm', 'small') {
      max-width: 400px;
    }
  }
}
