$border-radius                    : 6px !default;
$background                       : $neutral-grey !default;
$color                            : $neutral-grey-8 !default;
$background-hover                 : $neutral-grey-7 !default;
$color-hover                      : $brand-primary-text !default;

$nav-color                        : $color !default;
$nav-color-hover                  : $color-hover !default;

$not-in-month                     : $neutral-grey-4 !default;
$not-in-month-hover               : $neutral-grey-6 !default;

$disabled                         : #333 !default;
$selected                         : $brand-primary-text !default;
$selected-background              : $neutral-grey-7 !default;

$not-in-month-selected-background : $neutral-grey-7 !default;
$day-of-week                      : $not-in-month-hover !default;

$today-background                 : $selected-background !default;
$today-color                      : $selected !default;

.pickmeup {
  font-family: $secondary-font-family;
  background      : $background;
  border-radius   : $border-radius;
  -moz-box-sizing : content-box;
  box-sizing      : content-box;
  display         : inline-block;
  position        : absolute;
  touch-action    : manipulation;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.10), 0 8px 8px 0 rgba(0,0,0,0.10), 0 16px 16px 0 rgba(0,0,0,0.10), 0 32px 32px 0 rgba(0,0,0,0.10), 0 64px 64px 0 rgba(0,0,0,0.10);
  // @include rem(padding, units(1));

  &.pmu-flat {
    position : relative;
  }

  &.pmu-hidden {
    display : none;
  }

  .pmu-instance {
    display    : inline-block;
    // height     : 13.8em;
    padding    : units(1);
    text-align : center;
    width      : 300px;

    .pmu-button {
      color           : $color;
      cursor          : pointer;
      outline         : none;
      text-decoration : none;
    }

    .pmu-today {
      // background : green;
      // color      : $today-color;
      // border-radius: 50%;
    }

    .pmu-button:hover {
      background : $background-hover;
      color      : $color-hover;
      border-radius: 50%;
    }

    .pmu-not-in-month {
      color : $not-in-month;
    }

    .pmu-disabled,
    .pmu-disabled:hover {
      color  : $disabled;
      opacity: .5;
      cursor : default;
    }

    .pmu-selected {
      background : $selected-background;
      color      : $selected;
      border-radius: 50%;
    }

    .pmu-not-in-month.pmu-selected {
      background : $not-in-month-selected-background;
    }

    nav {
      color       : $nav-color;
      display     : -ms-flexbox;
      display     : -webkit-flex;
      display     : flex;
      line-height : 2em;
      @include rem(padding, 0 26px);

      *:first-child :hover {
        color : $nav-color-hover;
      }

      .pmu-prev,
      .pmu-next {
        display : none;
        height  : 2em;
        width   : 1em;

        &.pmu-button {
          &:hover {
            background: none;
            color: $color;
            border-radius: none;
          }
        }
      }
      .pmu-month {
        width : 14em;

        &.pmu-button {
          &:hover {
            background: none;
            color: $color;
            border-radius: none;
          }
        }
      }
    }

    .pmu-years,
    .pmu-months {
      * {
        display     : inline-block;
        line-height : 3.6em;
        width       : 3.5em;
      }
    }

    .pmu-day-of-week {
      color  : $day-of-week;
      cursor : default;
      @include rem(padding, 0 10px);
    }

    .pmu-day-of-week,
    .pmu-days {
      * {
        display     : inline-block;
        // line-height : 1.5em;
        width       : 36px;
        height: 36px;
        line-height: 2.2;
      }
    }

    .pmu-day-of-week * {
      // line-height : 1.8em;
    }
  }

  .pmu-instance:first-child .pmu-prev,
  .pmu-instance:last-child .pmu-next {
    display : block;
  }

  .pmu-instance:first-child,
  .pmu-instance:last-child {
    .pmu-month {
      width : 13em;
    }
  }

  .pmu-instance:first-child:last-child .pmu-month {
    width : 12em;
  }

  &:not(.pmu-view-days) .pmu-days,
  &:not(.pmu-view-days) .pmu-day-of-week,
  &:not(.pmu-view-months) .pmu-months,
  &:not(.pmu-view-years) .pmu-years {
    display : none;
  }
}
