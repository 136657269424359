/**
 * @molecule Calendar
 * @section Navigation > Sections
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @markup
 * <div class="calendar">
 *  <div class="calendar__header">
 *    <h6 class="heading heading--alt">Schedule</h6>
 *  </div>
 *  <div class="calendar__row">
 *    <div class="calendar__row__date"><h6 class="heading heading--alt">MAR<br>11</h6></div>
 *    <div class="calendar__row__activity">
 *      Exhibition Opening <br>
 *      I Know Not These My Hands<br>
 *      7pm, Fotografiska Stockholm
 *     </div>
 *   </div>
 *   <div class="calendar__row">
 *     <div class="calendar__row__date"><h6 class="heading heading--alt">MAR<br>11</h6></div>
 *     <div class="calendar__row__activity">
 *       Exhibition Opening <br>
 *       I Know Not These My Hands<br>
 *       7pm, Fotografiska Stockholm
 *     </div>
 *   </div>
 *   <div class="calendar__row">
 *     <div class="calendar__row__date"><h6 class="heading heading--alt">MAR<br>11</h6></div>
 *     <div class="calendar__row__activity">
 *       Exhibition Opening <br>
 *       I Know Not These My Hands<br>
 *       7pm, Fotografiska Stockholm
 *     </div>
 *   </div>
 *   <div class="calendar__row">
 *     <div class="calendar__row__date"><h6 class="heading heading--alt">MAR<br>11</h6></div>
 *     <div class="calendar__row__activity">
 *       Exhibition Opening <br>
 *       I Know Not These My Hands<br>
 *       7pm, Fotografiska Stockholm
 *     </div>
 *   </div>
 * </div>
 */

.calendar {
  font-family: $secondary-font-family;
  border: 1px solid $brand-primary-text;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &--dark {
    border-color: $neutral-grey-8;

    .calendar__header,
    .calendar__row,
    .calendar__row__date,
    .calendar__row__activity {
      border-color: $neutral-grey-8;
    }
  }

  &__header {
    border: 1px solid $brand-primary-text;
    @include rem(padding, units(2));
    text-align: center;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;

    &__date {
      @include rem(padding, units(1));
      @include rem(max-width, 190px);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $brand-primary-text;
      width: 100px;
      text-align: center;
      max-width: 190px;
    }

    &__activity {
      @include rem(padding, units(1));
      border: 1px solid $brand-primary-text;
      width: auto;
      flex: 1;
      display: flex;
      align-items: center;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
