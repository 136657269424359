.amex {
  &__banner {
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__line {
    //background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;


    & > * {
      align-self: center;
    }

    .inner {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      justify-content: flex-start;
      padding: 6px 32px 6px 0;
      @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
      height: #{46px + 12px};
      @media screen and (max-width: 767px) {
        height: auto;
      }
    }

    span {
      margin-top: -1px;
      white-space: nowrap;
      font-size: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      align-self: center;

      margin-right: 16px;
      @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    .image {
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-start;
      flex-direction: row;
      text-align: left;
      align-self: center;
      img {
        display: block;
        width: 100%;
        max-width:  402px;
        height: auto;
      }
    }
  }


}
