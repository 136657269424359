$brand-primary: #000;
$brand-primary-text: #fff;

$neutral-grey: #f8f9fa;
$neutral-grey-1: #f1f3f5;
$neutral-grey-2: #e9ecef;
$neutral-grey-3: #dee2e6;
$neutral-grey-4: #ced4da;
$neutral-grey-5: #adb5bd;
$neutral-grey-6: #868e96;
$neutral-grey-7: #495057;
$neutral-grey-8: #343a40;
$neutral-grey-9: #212529;
$neutral-grey-10: #202428;

@include if-theme('fgny') {
  $neutral-grey-9: #000 !global;
  $fgny-error-color: #F22613 !global;
}

$utility-pomegranate: #F22613;

@include if-theme('fgny') {
  $utility-pomegranate: #F22613 !global;
}

$utility-junglegreen: #26C281;

$overlay-dark: $neutral-grey-9;
$overlay-light: #414141;


$theme: (
  'background': (
    'brand-primary': $brand-primary,
    'inverted': $brand-primary-text,
    'neutral-grey': $neutral-grey,
    'neutral-grey-1': $neutral-grey-1,
    'neutral-grey-2': $neutral-grey-2,
    'neutral-grey-3': $neutral-grey-3,
    'neutral-grey-4': $neutral-grey-4,
    'neutral-grey-5': $neutral-grey-5,
    'neutral-grey-6': $neutral-grey-6,
    'neutral-grey-7': $neutral-grey-7,
    'neutral-grey-8': $neutral-grey-8,
    'neutral-grey-9': $neutral-grey-9,
    'pomegranate': $utility-pomegranate,
    'junglegreen': $utility-junglegreen
  ),
  'text': (
    'brand-primary': $brand-primary-text,
    'dark': $neutral-grey-8,
    'darkest': $neutral-grey-9,
    'notice': $utility-pomegranate,
  )
);

@include theme-init($theme);
