.cards {
  @include rem(margin, units(1) 0);
  display: inline-block;
  flex-flow: row wrap;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: relative;

  &--current {
    .card {
      width: 560px;
      height: 420px;

      @include breakpoint('palm', 'small') {
        width: 100%;
      }
    }
  }

  &--popular {
    .card {
      width: 50%;
      height: 420px;
    }
  }
}
