[data-popup] {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(#000,.3);
    user-select: none;
    padding: 30px 0 0;
    text-align: center;
    font-size: 0;
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
    }
    overflow: auto;
    transition: opacity 0.3s, visibility 0.3s, z-index 0.3s;
    &.is-open {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
    }
    // &.is-small {
    //     @include breakpoint('xmedium') {
    //         padding-top: 180px;
    //     }
    // }
}
        
[data-popup-container] {
    position: relative;
    z-index: 1;
    font-size: 12px;
    vertical-align: middle;
    display: inline-block;
    max-width: 620px;
    width: 100%;
    padding: 30px;
    height: 100%;
    // background-color: #fff;
	@media screen and (max-width: 767px) {
        padding: 25px 15px
    }
}
[data-popup-close] {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    font-size: 0;
    border: none;
    padding: 0;
	@media screen and (max-width: 767px) {
        top: 0;
        right: 15px;
    }
    &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
    }
    svg {
        display: block;
        width: 17px;
        height: 17px;
        fill: #fff;
    }
}

.modal {
    &__inner {
        height: 100%;

        .box {
            height: 100%;
        }

        .bookatable-iframe {
            height: 100%;
        }
    }
}