* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  //@include rem-baseline;
  font-size: $base-font-size;
  @include breakpoint('palm', 'small') {
    font-size: $base-font-size-mobile;
  }
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


body {
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  color: $neutral-grey-9;
  font-size: 1rem;
  line-height: $base-line-height;
  overflow: auto;
  height: 100%;
  @include if-theme('fgny') {
    background-color: $bg-color;
  }

  &.info-open,
  &.search-open {
    overflow: hidden;
  }

  &.theme-default {
    background: $brand-primary-text;
    color: $neutral-grey-9;

    .full-page {
      background: $brand-primary-text;
    }
  }

  &.theme-dark {
    background: $neutral-grey-9;
    color: $brand-primary-text;

    .full-page {
      background: $neutral-grey-9;
    }
  }

  &.theme-light {
    background: $neutral-grey-1;
    color: $neutral-grey-9;

    .full-page {
      background: $neutral-grey-1;
    }
  }

  &.webview {
    .site-header,
    .info-sidebar,
    .info-sidebar__mob,
    .footer-hero,
    .footer,
    .footer-companies {
      display: none;
    }

    .hero {
      margin-top: 0;
    }
  }
}

.body-wrapper {
  // position: relative;
  // perspective: 1200px;
  // perspective-origin: 50% -50%;
}


hr {
  border: 0;
  height: 2px;
  background: $neutral-grey-9;
  @include if-theme('fgny') {
    background: #000;
  }
}

.stuck {
  @include breakpoint('small') {
    position: fixed;
    top: 100px;
  }
}

.stuck-off {
  @include breakpoint('small') {
    position: absolute;
    bottom: 0;
    top: 85%;
  }
}


.map {
  width: 100%;
  height: 400px;
  margin-bottom: 6px;

  &--small {
    height: 280px;
  }
}

.disable-section {
  opacity: .2;
  pointer-events: none;
}

.disable-body,
.info-open {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  height: 100%;
  width: 100%;

  @include breakpoint('palm', 'medium') {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .full-page {
    pointer-events: none;
  }
}

.ReactModal__Body--open {
  //-webkit-overflow-scrolling: touch;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .full-page {
    display: none;

  }
  .full-page {
    display: none;

  }
  .search-form-container{
    display: none;
  }


}

.single-exhibition {
  .panel-layout {
    @include fluid-type(margin-bottom, breakpoint('small'), breakpoint('xlarge'), units(7), units(10));

    .panel-grid .panel-grid-cell .widget {
      @include fluid-type(margin-top, breakpoint('small'), breakpoint('xlarge'), units(7), units(10));

      &.widget_section_gallery,
      &.widget_exhibition_current_list {
        @include fluid-type(margin-top, breakpoint('small'), breakpoint('xlarge'), units(4), units(6));
      }

      &:first-child {
        @include fluid-type(margin-top, breakpoint('small'), breakpoint('xlarge'), units(3), units(10));
      }
    }
  }
}

.min-height-240 {
  min-height: 240px;
}

.wpml-ls-item-legacy-list-horizontal {
  display: inline-block;
}

.m-mt2 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-top, units(2));
  }
}
.m-mt3 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-top, units(3));
  }
}
.m-mt4 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-top, units(4));
  }
}

.m-mb0 {
  @include breakpoint('palm', 'small') {
    margin-bottom: 0 !important;
  }
}

.m-mb1 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-bottom, units(1));
  }
}

.m-mb2 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-bottom, units(2));
  }
}

.m-mb3 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-bottom, units(3));
  }
}

.m-mb4 {
  @include breakpoint('palm', 'small') {
    @include rem(margin-bottom, units(4));
  }
}

.modal-btn-close {
  cursor: pointer;
  display: flex;
}

input,
textarea {
  caret-color: $neutral-grey-9;
}

// Scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: #fafff8;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: $neutral-grey-7;
  border: 0 none $brand-primary-text;
  // border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: $neutral-grey-7;
}

::-webkit-scrollbar-thumb:active {
  background: $neutral-grey-7;
}

::-webkit-scrollbar-track {
  background: rgba(255,255,255,0);
  border: 0 none rgba(255,255,255,0);
  // border-radius: 100px;
}

::-webkit-scrollbar-track:hover {
  background: #fafff8;
}

::-webkit-scrollbar-track:active {
  background: rgba(255,255,255,0);
}

::-webkit-scrollbar-corner {
  // background: transparent;
}

.nowrap {
  white-space: nowrap;
}
