$theme-settings: ();
$theme: ();

@mixin theme-init($settings) {
  $theme-settings: map-merge($theme-settings, $settings) !global;
  $theme: $theme-settings !global;
  @include generateThemeClasses();
}

@mixin generateThemeClasses() {
  @each $type, $map in $theme {
    @each $name, $hex in $map {
      .t-#{$type}-#{$name} {
        @if ($type == 'background') {
          background-color: $hex;
        } @elseif ($type == 'text') {
          color: $hex;
        } @elseif ($type == 'border') {
          border-color: $hex;
        }
      }
    }
  }
}

@mixin theme($key, $color) {
  @if ($key == 'background') {
    background-color: color-get($color, $key);
  } @elseif ($key == 'text') {
    color: color-get($color, $key);
  } @elseif ($key == 'border') {
    border-color: color-get($color, $key);
  }
}

@mixin color($color) {
  color: color-get($color, 'text');
}

@mixin background($color) {
  background-color: color-get($color, 'background');
}

@function color-get($color, $key) {
  @if map-has-key($theme, $key) {
    @return map-get(map-get($theme, $key), $color);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

@function color($color) {
  @return color-get($color, 'text');
}

@function background($color) {
  @return color-get($color, 'background');
}
