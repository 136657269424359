/**
 * @atom Link
 * @section Navigation > Text
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @modifiers
 *  .link--heading
 * @markup
 *  <a href="#" class="link">Link</a>
 *  <a href="#" class="link link--heading">Link Heading</a>
 *  <a href="#" class="link link--heading">Link with arrow <span>&#10230;</span></a>
 */

.link {
  font-family: $secondary-font-family;

  &--heading {
    font-family: $base-font-family;
    transition: .3s ease-in-out;

    &:hover {
      span:not(.no-transform):not(.text) {
        transform: translateX(units(1));
      }
    }

    span {
      display: inline-block;
      transition: .3s ease-in-out;
    }
  }

  &--underlined {
    text-decoration: underline;
  }
}
