.align-baseline { vertical-align: baseline }
.align-top      { vertical-align: top }
.align-middle   { vertical-align: middle }
.align-bottom   { vertical-align: bottom }

.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }
.table        { display: table }
.table-cell   { display: table-cell }
.hide   { display: none }

.relative { position: relative }
.fixed { position: fixed }
.absolute { position: absolute }
.static { position: static }

.top { top: 0 }
.left { left: 0 }
.bottom { bottom: 0 }
.right { right: 0 }

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix { @include clearfix }

.float-left  { float: left }
.float-right { float: right }
.float-none { float: none !important }

.fit { max-width: 100% }

.border-box { box-sizing: border-box }

.fill { width: 100%; height: 100% }
.fill-width { width: 100% }
.fill-height { height: 100% }

.text-center { text-align: center }
.text-left { text-align: left }
.text-right { text-align: right }

.img-round { border-radius: 100% }
