.slider {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;


    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-thumb {
    @include rem(margin-top, -12px);
    -webkit-appearance: none;
    background: $brand-primary-text;
    border: 1px solid rgba(0,0,0,0.10);
    box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.10), 0 3px 3px 0 rgba(0,0,0,0.05);
    height: 28px;
    width: 28px;
    border-radius: 100%;
    cursor: pointer;

  }

  &::-moz-range-thumb {
    border: 1px solid #000000;
    background: $brand-primary-text;
    border: 1px solid rgba(0,0,0,0.10);
    box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.10), 0 3px 3px 0 rgba(0,0,0,0.05);
    height: 28px;
    width: 28px;
    border-radius: 100%;
    cursor: pointer;
  }

  &::-ms-thumb {
    border: 1px solid #000000;
    background: $brand-primary-text;
    border: 1px solid rgba(0,0,0,0.10);
    box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.10), 0 3px 3px 0 rgba(0,0,0,0.05);
    height: 28px;
    width: 28px;
    border-radius: 100%;
    cursor: pointer;
  }


  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: $neutral-grey-4;
    border-radius: 5px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: $neutral-grey-8;
  }

  &::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: $neutral-grey-4;
    border-radius: 5px;
  }


  &::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $neutral-grey-4;
    border-radius: 5px;
  }

  &:focus::-ms-fill-lower {
    background: $neutral-grey-4;
  }

  &::-ms-fill-upper {
    background: $neutral-grey-4;
    border-radius: 5px;
  }

  &::focus::-ms-fill-upper {
    background: $neutral-grey-8;
  }
}
