@mixin fade($gradient-direction, $gradient-color) {
  @include rem(bottom, 1px);
  @include rem(width, units(4));
  @include rem(height, units(3));
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  position: absolute;
  transition-duration: .3s;
  background: linear-gradient(to $gradient-direction, $gradient-color 45%, rgba($gradient-color, .4));

  &.scrolling {
    visibility: visible;
    opacity: 1;
    transition-duration: .3s;
  }

  .icon {
    position: relative;
    font-size: 14px;
  }
}

@mixin scrolling-links() {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabs {
  @include clearfix;
  @include scrolling-links();
  // width: 100%;
  font-family: $secondary-font-family;
  // overflow-x: auto;
  // white-space: nowrap;
  // -webkit-overflow-scrolling: touch;
  position: relative;
  display: flex;
  width: calc(100% + .02px);

  // @include breakpoint('palm', 'small') {
  //   flex-flow: column wrap;
  //   @include rem(margin-top, units(2));
  //   max-height: 100px;

  //   &.expand {
  //     max-height: 100%;
  //   }
  // }

  .play & {
    .tabs--container {
      .fading-right {
        @include fade(left, $neutral-grey-9);
      }

      .fading-left {
        @include fade(right, $neutral-grey-9);
      }
    }

    .current,
    .is-active,
    .active {

      a {
        color: $brand-primary-text;

        &:before {
          background: $brand-primary-text;
        }
      }

    }

  }


  &--container {
    @include clearfix;
    position: relative;

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 1px;
      left: 0;
      background: $neutral-grey-5;
    }

    &::-webkit-scrollbar {
      display: none;  // Safari and Chrome
    }

    // &:after {
    //   content: '';
    //   position: absolute;
    //   background: linear-gradient(to right, transparent, rgba(255, 255, 255, .6));
    //   top: 0;
    //   right: 0;
    //   height: 100%;
    //   width: 100px;
    //   pointer-events: none;
    // }

    .fading-right {
      @include fade(left, $brand-primary-text);
      @include rem(right, -5px);

      .play & {
        @include fade(left, $neutral-grey-9);
      }

      .icon {
        @include rem(right, -38px);
      }
    }

    .fading-left {
      @include fade(right, $brand-primary-text);
      @include rem(left, -5px);

      .play & {
        @include fade(right, $neutral-grey-9);
      }

      .icon {
        @include rem(left, 6px);
      }
    }
  }

  .current,
  .is-active,
  .active {
    a {
      color: $neutral-grey-9;
      position: relative;
      font-weight: $font-weight-bold;

      &:before {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 1px;
        left: 0;
        background: $neutral-grey-9;
        z-index: 1;
      }
    }
  }

  &__item {
    @include rem(padding, 0 units(1));
    list-style: none;

    &:first-child {
      padding-left: 0;
    }

    a {
      @include rem(padding, units(1) 0);
      text-transform: uppercase;
      display: block;
      color: $neutral-grey-5;
    }
  }
}
