
/**
 * @molecule Label
 * @section Navigation > Label
 * @description
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum erat vel lacus molestie blandit. Praesent et risus quis quam auctor elementum nec id diam. Nam odio diam, sagittis in urna vitae, egestas vulputate libero.
 * @modifiers
 *  .label--inverted
 *  .label--secondary
 * @markup
 *  <div class="label up">Label</div>
 *  <div class="label down label--inverted">Label inverted</div>
 *  <div class="label left label--secondary">Label secondary</div>
 *  <div class="label right">Label</div>
 */


.label {
  position: relative;
  font-family: $secondary-font-family;
  background: $neutral-grey-8;
  color: $brand-primary-text;
  display: inline-block;
  border-radius: 3px;
  @include font('pi');
  @include rem(letter-spacing, 1.7px);
  @include rem(padding, units(.2) units(.8));
  text-transform: uppercase;
  font-weight: $font-weight-bold;

  &:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 5px;
  }

  &.up {
    &:after {
      bottom: 100%;
      left: 50%;
      border-color: transparent;
      border-bottom-color: $neutral-grey-8;
      margin-left: -5px;
    }
  }

  &.down {
    &:after {
      top: 100%;
      left: 50%;
      border-color: transparent;
      border-top-color: $neutral-grey-8;
      margin-left: -5px;
    }
  }

  &.left {
    &:after {
      right: 100%;
      top: 50%;
      border-color: transparent;
      border-right-color: $neutral-grey-8;
      margin-top: -5px;
    }

  }

  &.right {
    &:after {
      left: 100%;
      top: 50%;
      border-color: rgba(213, 213, 213, 0);
      border-left-color: $neutral-grey-8;
      margin-top: -5px;
    }
  }

  &--inverted {
    background: $neutral-grey-3;
    color: $neutral-grey-8;
    &.up {
      &:after {
        border-bottom-color: $neutral-grey-3;
      }
    }
    &.down {
      &:after {
        border-top-color: $neutral-grey-3;
      }
    }
    &.left {
      &:after {
        border-right-color: $neutral-grey-3;
      }
    }
    &.right {
      &:after {
        border-left-color: $neutral-grey-3;
      }
    }
  }

  &--secondary {
    background: $brand-primary-text;
    border: 1px solid #DDDDDD;
    color: $neutral-grey-8;

    &.up {
      &:after {
        border-bottom-color: #DDDDDD;
      }
    }
    &.down {
      &:after {
        border-top-color: #DDDDDD;
      }
    }
    &.left {
      &:after {
        border-right-color: #DDDDDD;
      }
    }
    &.right {
      &:after {
        border-left-color: #DDDDDD;
      }
    }
  }
}
