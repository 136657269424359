@mixin if-theme($name: "") {
  @if not variable-exists(inside-theme) {

    $inside-theme: true;
    @if $name == "" {
      @at-root html:not([theme]) #{if(&, "&", "")} {
        @content;
      }
    } @else {
      @at-root html[theme="#{$name}"] #{if(&, "&", "")} {
        @content;
      }
    }
  }
}
