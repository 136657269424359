/**
 * @structure Container
 * @section Navigation > Layout
 * @description
 *  Container with standard with 1170px
 * @modifiers
 *  .container--slim for 775px
 *  .container--wide for 1440px
 *  .container--flush to remove horizontal padding
 * @markup
 *  <div class="container"></div>
 */

.container {
  @include rem(max-width, 1170px);
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  &--superslim {
    @include rem(max-width, 380px);
    @include breakpoint('palm', 'small') {
      max-width: 100%;
    }
  }

  &--halfslim {
    @include rem(max-width, 480px);
  }

  &--slim {
    max-width: 904px;

    @include rem(padding, 0 units(1));
    @include breakpoint('palm', 'small') {
      padding: 0 20px;
    }
  }

  &--narrow {
    @include rem(max-width, 975px);
    padding: 0;
  }

  &--wide {
    display: flex;
    flex-direction: column;
    @include rem(max-width, 1440px);
    padding: 0;
  }

  &--flush {
    padding: 0;
  }
}
