.quantity {
  &.custom {
    @include rem(margin, units(2) 0);
    width: auto;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-family: $secondary-font-family;
    // @include rem(padding-bottom, units(1));

    .custom-qty {
      @include rem(width, units(3));
      outline: 0;
      margin: 0;
      padding: 0;
      border: 0;
      text-align: center;
      color: $neutral-grey-9;
      -webkit-text-fill-color: $neutral-grey-9;
      opacity: 1;
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: none;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.disabled {
        opacity: .5;
      }
    }

    .minus-btn,
    .plus-btn {
      cursor: pointer;
      border: 0;
      outline: 0;
      width: 23px;
      height: 23px;
      background-repeat: no-repeat;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      background-size: 100% 100%;
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: none;
    }

    .minus-btn {
      background-image: url('~images/icons-new/minus.svg');
    }
    .plus-btn {
      background-image: url('~images/icons-new/plus.svg');
    }
  }
}

.group-counter-row {

  &:not(.divider-hidden) {
    border-bottom: 2px solid $brand-primary;
    .input-counter-row {
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &.invalid {
    .input-counter__value {
      color: #E30D0D;
      -webkit-text-fill-color: #E30D0D;
      @include if-theme('fgny') {
        color: $fgny-error-color;
        -webkit-text-fill-color: $fgny-error-color;
      }
    }
  }

}

.input-counter-row {
  border-bottom: 1px solid $neutral-grey-7;
  @include if-theme('fgny') {
    border-bottom: 1px solid #000;
  }
  padding-bottom: 15px;
  margin: 15px 0;
  text-align: left;
  /*@include breakpoint('palm', 'small') {
    padding-bottom: 10px;
    margin: 10px 0;
  }*/

  &.invalid {
    .input-counter__value {
      color: #E30D0D;
      -webkit-text-fill-color: #E30D0D;
    }
  }

}

.input-counter-row,
.group-counter-row {
  .help-text {
    font-size: .8em;
    bottom: .5em;
    opacity: .5;
  }
}

.input-timeslot-row {
  font-family: $secondary-font-family;
  @include fgny-b1();

  label {
    @include if-theme('fgny') {
      line-height: 26px;
    }
  }

  text-align: left;
  //@include rem(padding-bottom, 33px);
  margin: 33px 0 38px 0;
  @media screen and (max-width: 767px) {
    margin: 24px 0 36px 0;
  }

  .timeslot {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .timeslot__container {
      display: flex;
      justify-content: space-between;
      .timeslot__field {
        @media screen and (min-width: 768px) {
          height: 28px;
        }

        overflow: visible;

        @media screen and (max-width: 767px) {
          margin-top: 14px - 7px;
        }

        &:not(:last-child) {

          margin-right: 20px;
          @media screen and (max-width: 767px) {
            margin-right: 0;
          }
        }

        .react-datepicker-wrapper {
          @include if-theme('fgny') {
            font-size: 0.9em;
            //font-size: 18px;
          }

          @media screen and (min-width: 768px) {
            margin-top: -13px;
          }


          @media screen and (min-width: 768px) {
            width: 118px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }


        }

        .select {
          @include if-theme('fgny') {
            font-size: 0.9em;
            //font-size: 18px;
          }

          @media screen and (min-width: 768px) {
            margin-top: -13px;
          }

          &.timeslot__field__select_date {
            @media screen and (min-width: 768px) {
              width: 118px;
            }
          }
          &.timeslot__field__select_time {
            select {
              padding: 0 20px 0 0;
            }
            @media screen and (min-width: 768px) {
              width: 118px;
            }
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }




  }

}


.input-counter {
  width: 100%;
  display: flex;
  justify-content: space-between;


  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;

  // label  {
  //   padding: calc((23px - 1rem)/2) 0;
  // }

  &.disabled {
    label {
      opacity: .2;
    }
  }

  &__container {
    white-space: nowrap;
  }

  &__minus,
  &__plus {
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    &.disabled {
      opacity: .2;
      pointer-events: none;
    }
  }

  &__value {
    @include rem(width, units(2.5));
    outline: 0;
    margin: 0;
    border: 0;
    text-align: center;
    color: $neutral-grey-9;
    -webkit-text-fill-color: $neutral-grey-9;
    opacity: 1;
    display: inline-block;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.disabled {
      opacity: .5;
    }
  }

  &__minus {
    background-image: url('~images/icons-new/minus.svg');
  }

  &__plus {
    background-image: url('~images/icons-new/plus.svg');
  }
}

.summary-group {
  //@include fgny-b2;


}

.summary-timeslot.flex {
  p {
    span:not(:last-child) {
      margin-right: 0.3rem;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.cart-box {

  &__names {
    & > input, > select {
      &:nth-child(2n+1) {
        margin-right: 0.5rem;
      }

      &:nth-child(2n) {
        margin-left: 0.5rem;
      }

      width: calc(50% - 0.5rem);
    }
  }

  .cart-box__action {
    margin-top: 22px;
  }

  .cart-box__min-height {
    @media screen and (max-width: 767px) {
      min-height: auto !important;
    }
  }
}
