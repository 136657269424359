.activities {

  &-list {
    &__filter {
      display: flex;

      @include breakpoint('palm', 'medium') {
        display: block;
      }

      &__checkboxes {
        display: flex;
      }
    }
  }
}
