.checkout {
  &-container {
    position: relative;

    &.loading {
      opacity: .7;
    }
  }

  &-loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .loading & {
      display: flex;
    }
  }

  &-table {
    @include rem(padding, units(2) 0);
    @include rem(margin, units(2) 0 0 0);
    font-family: $secondary-font-family;
    text-align: left;
    width: 100%;
    border-collapse: collapse;

    thead {
      border-bottom: 1px solid $neutral-grey-9;

      th {
        @include font('xi');
        @include rem(padding, units(1) 0);
        font-weight: 700;
        text-transform: uppercase;
      }

      td {
        vertical-align: middle;
      }
    }

    tbody {
      border-bottom: 1px solid $neutral-grey-9;

      tr {
        border-top: 1px solid rgba($neutral-grey-9, .2);

        &:first-child {
          border-top: 0;
        }
      }

      td {
        @include rem(margin, units(2) 0);
        vertical-align: middle;
      }
    }

    th {
      @include font('xi');
      @include rem(padding, 9px 0);
      font-weight: 700;
      text-transform: uppercase;

      &.product-total {
        text-align: right;
      }
    }

    td {
      vertical-align: middle;

      &.product-total {
        text-align: right;
      }
    }

    .product-addon {
      &-label {
        @include rem(margin-bottom, units(0));
        font-weight: $font-weight-bold;
        display: none;
      }
    }

    .product-subtotal {
      text-align: right;
    }

    .product-price-palm {
      display: none;
    }

    .product-amount-palm {
      display: none;
    }

    @include breakpoint('palm', 'small') {
      tbody {
        border-top: 1px solid rgba($neutral-grey-9, 1);
      }

      .cart_item {
        position: relative;
        display: block;
        padding: 1px 0;
      }

      .product-name {
        @include rem(min-height, 70px);
        position: relative;
        width: 200px;
        display: flex;
        align-items: center;

        .name {
          @include text-truncate();
          @include font('ny');
          width: 100%;
          position: absolute;
          top:  0;
          left: 3rem;
        }

        img {
          @include rem(width, 70px);
          display: block;
          height: auto;
        }
      }

      .product-price-palm {
        @include font('ny');
        display: block;
        position: absolute;
        top: 2rem;
        left: 3.5rem;
        margin: 0;
      }

      .product-price {
        display: none;
      }

      .product-addon {
        display: block;
        width: 100%;
        min-width: 100%;

        &-label {
          display: block;
        }
      }

      .product-quantity {
        display: none;
      }

      .product-amount-palm {
        @include font('ny');
        display: block;
        position: absolute;
        top: 1rem;
        left: 3.5rem;
        margin: 0;

      }

      .product-total {
        // position: relative;
        display: block;

        .amount {
          position: absolute;
          top: 3.1rem;
          right: 0;
          font-weight: 700;

          &:before {
            content: 'Total: ';
            font-weight: 700;
            position: absolute;
            left: -100%;
          }
        }
      }

      thead {
        border: 0;

        tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }
    }

    &-totals {
      @include rem(margin, units(2) 0);
      font-family: $secondary-font-family;
      text-align: left;
      margin: 0 -1px 24px 0;
      width: 100%;
      border-collapse: collapse;

      tbody {
        //change the order of rows in table
        display: flex;
        flex-direction: column;
        width: 100%;

        tr {
          @include breakpoint('palm', 'small') {
            justify-content: space-between;
          }

          td, th {
            align-self: center;
          }

          display: flex;
          flex-direction: row;

          &.order-total {
            order: 100;
          }

        }

      }


      tr {
        @include font('ny');
        border-top: 1px solid rgba($neutral-grey-9, .2);


        &:first-child {
          border-top: 0;
          // border-bottom: 1px solid rgba($neutral-grey-9, .2);
        }

        &.order-total {
          border-bottom: 1px solid $neutral-grey-9;

          th,
          td {
            font-weight: 700;
          }
        }
      }

      th {
        padding: 9px 0;
        width: 25%;
        font-weight: 300;

        @include breakpoint('palm', 'small') {
          width: 50%;
        }
      }

      td {
        &[colspan="2"] {
        width: 100%;
      }

        padding: 22px 0;
        vertical-align: middle;
        @include breakpoint('palm', 'small') {
          text-align: right;
        }
      }

      #airmee_schedule_field {
        display: flex;
        padding: 0;
        margin: -22px 0;

        @include breakpoint('palm', 'small') {
          justify-content: space-between;

          &::before {
            content: none;
          }
          &::after {
            content: none;
          }
        }

        label {
          padding: 9px 0;
          width: 25%;
          font-weight: 300;
          align-self: center;

          @include breakpoint('palm', 'small') {
            width: 50%;
            text-align: left;
          }

        }
        .woocommerce-input-wrapper {
          padding: #{22px - (55px - 32px)/2} 0;
          //vertical-align: middle;
          align-self: center;

          @include breakpoint('palm', 'small') {
            text-align: right;
          }

          @include select($selector: ".select", $border-bottom: none);
          .select {
            padding-right: 20px;
          }

        }

      }

    }

  }


}
