/**
 * @atom Button
 * @section Navigation > Buttons
 * @description
 *  Buttons for various purposes.
 * @modifiers
 *  .btn--primary primary button
 *  .btn--inverted inverted primary button
 *  .btn--secondary secondary button
 *  .btn--flush flush margin
 *  .disabled disabled state
 * @markup
 *  <button class="btn btn--primary">Primary</button>
 *  <button class="btn btn--inverted">Inverted</button>
 *  <button class="btn btn--secondary">Secondary</button>
 */
@mixin fgny-button-none() {
  background: none;
}
@mixin fgny-button-next($padding: 20px, $image: '~images/fgny/icons/button-arrow.svg') {
  background-repeat: no-repeat;
  background-position: calc(100% - #{$padding}) center;
  padding-right: #{$padding + 17px + 10px};
  
  background-image: url($image);
  &:hover {
    background-position: calc(100% - #{$padding - 4px}) center;
  }
}

@mixin fgny-button-next-hover($image: '~images/fgny/icons/button-arrow-hover.svg') {
  &:hover {
    background-image: url($image);
  }
}

@mixin fgny-button-underline($color) {
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: $color;
    transition: width .35s;
    top: -1px;
    position: relative;
  }
  &:hover:after {
    width: 100%;
  }
  &.mob-small:after {
    top: 0px;    
  }
}

.button,
.btn,
%btn {
  @include rem(margin, units(1) 0);
  @include rem(padding, units(.5) units(3));
  font-family: $secondary-font-family;
  background: $neutral-grey-9;
  color: $brand-primary-text;
  font-weight: $font-weight-bold;

  @include if-theme('fgny') {
    padding: 10px 0;
    background: none;
    //line-height: 33px;
    line-height: 25px;
    border-radius: 0;
    -webkit-border-radius: 0px;
    @include fgny-text-button($color: #000);
    @include fgny-button-underline($color: #000);

    &:hover, &.active {
      color: #000;
    }


    &.mob-small {
      @include breakpoint('palm', 'small') {
        @include rem(margin-top, units(0.75));
        @include rem(margin-bottom, units(0.75));
        line-height: 17px;
        font-size: 15px;
        letter-spacing: 0.75px;
      }
      
    }
  }



  outline: 0;
  border: 0;
  cursor: pointer;
  max-width: 100%;
  display: inline-block;
  //vertical-align: middle;
  text-align: center;
  transition: all .1s ease-out;
  text-decoration: none;
  position: relative;

  &.btn--next {
    @include if-theme('fgny') {
      color: #3737E6;
      @include fgny-button-next($padding: 4px, $image: '~images/fgny/icons/button-arrow-dark.svg');
      @include fgny-button-next-hover($image: '~images/fgny/icons/button-arrow-dark-hover.svg');
      @include fgny-button-underline($color: #3737E6);
      &:hover, &.active {
        color: #3737E6;
      }
      &:after {
        content: none;
      }
      &.mob-small {
        @include breakpoint('palm', 'small') {
          background-position: calc(100% - 4px) center;
          padding-right: #{4px + 17px + 10px};
          &:hover {
            background-position: calc(100% - #{4px - 4px}) center;
          }
        }
      }
    }


  }

  &.btn--cta {
    @include if-theme('fgny') {
      &:after {
        content: none;
      }
      line-height: 33px;
      color: #f5f2f0;
      background-color: #3737E6;
      padding: 10px 20px;

      &.disabled,
      &:disabled {
        background: $neutral-grey-5;
        color: $brand-primary-text;
        cursor: not-allowed;
      }

      &.mob-small {
        @include breakpoint('palm', 'small') {
          line-height: 17px;
          padding: 10px 10px;
        }
      }
    }

  
  }

  &.btn--cta-next {
    @include if-theme('fgny') {
      &:after {
        content: none;
      }
      line-height: 33px;
      color: #f5f2f0;
      background-color: #3737E6;
      padding: 10px 20px;
      @include fgny-button-next;

      &.mob-small {
        @include breakpoint('palm', 'small') {
          padding: 10px 10px;
          line-height: 17px;
          background-position: calc(100% - 10px) center;
          padding-right: #{10px + 17px + 10px};
          &:hover {
            background-position: calc(100% - #{10px - 4px}) center;
          }
        }
      }   
    }

 
  }

  &.btn--back {
    @include if-theme('fgny') {
      //color: #5a5a5a;
      background-color: none;
      background-repeat: no-repeat;
      background-position: 4px center;
      padding-left: #{4px + 17px + 11px};
      background-image: url('~images/fgny/icons/button-back.svg');
      &:after {
        content: none;
      }
      &:hover {
        //color: #000;
        background-position: 0px center;
        background-image: url('~images/fgny/icons/button-back-hover.svg');
      }
    }
  }

  &.add-loader {
    position: inherit;
  }

  &.loading {
    opacity: .25;
    cursor: not-allowed;

    &:after {
      @include rem(width, units(3));
      @include rem(height, units(3));
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('~images/svg-loaders/puff.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 10;
      opacity: 1;
    }
  }

  &-split {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .btn {
      @include rem(padding, units(.8) units(2));
      margin: 0;

      &:first-child {
        border-radius: 100px 0 0 100px;
        width: 50%;
        border-right: 1px solid $neutral-grey-1;
      }

      &:last-child {
        border-radius: 0 100px 100px 0;
        width: 50%;
        border-left: 1px solid $neutral-grey-1;
      }
    }
  }

  &--primary {
    @include rem(padding, units(.8) units(2));
    @include if-theme() {
      border-radius: 100px;
      font-weight: $font-weight-medium;
    }

    &:hover {
      background: $neutral-grey-8;
    }

    &.disabled,
    &:disabled {
      opacity: .5;
      //background: $neutral-grey-5;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }

    &.single_add_to_cart_button {
      background: $green-button-bg;
    }
  }

  &--secondary {
    @include rem(padding, units(.8) units(2));
    border-radius: 100px;
    font-weight: $font-weight-medium;
    background: $neutral-grey-2;
    color: $neutral-grey-9;

    &:hover {
      background: $neutral-grey-3;
    }

    &.disabled,
    &:disabled {
      background: $neutral-grey-5;
      color: $brand-primary-text;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &--success {
    @include rem(padding, units(.8) units(2));
    background: $utility-junglegreen;
    border-radius: 100px;
    font-weight: $font-weight-medium;

    &:hover {
      background: lighten($utility-junglegreen, 10%);
    }

    &.disabled,
    &:disabled {
      opacity: .5;
      //background: $neutral-grey-5;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &--filter {
    @include rem(padding, units(.8) units(2));
    @include rem(margin-bottom, units(2));
    border-radius: 100px;
    font-weight: $font-weight-medium;
    background: $neutral-grey-2;
    color: $neutral-grey-9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      @include rem(margin-right, units(1));
    }

    @include breakpoint('small') {
      display: none;
    }

    &:hover {
      background: darken($neutral-grey-2, 10%);
    }

    &.disabled,
    &:disabled {
      opacity: .5;
      //background: $neutral-grey-5;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &--pills {
    @include if-theme() {
      @include rem(padding, 0 units(2));
      @include rem(line-height, units(2));
      @include font('ny');
      background: transparent;
      color: $neutral-grey-7;
      border: 1px solid $neutral-grey-7;
      border-radius: 4px;
      font-weight: $font-weight-medium;
      &:hover {
        box-shadow: 0 0 1px 0 $neutral-grey-7;
      }
    }


    &.nav {
      &.btn--next {
        @include if-theme('fgny') {
          @include fgny-button-next($padding: 4px, $image: '~images/fgny/icons/button-arrow-black.svg');
          @include fgny-button-next-hover($image: '~images/fgny/icons/button-arrow-dark-hover.svg');

          &.active:not(*[href]) {
            &:hover {
              cursor: auto;

              //@include fgny-button-next($padding: 4px, $image: '~images/fgny/icons/button-arrow-black.svg');
              //color: #000;
              //&.active {
              //  color: #3737E6;
              //  @include fgny-button-next($padding: 4px, $image: '~images/fgny/icons/button-arrow-hover.svg');
              //}
            }
          }

          &.active {
            color: #3737E6;
            @include fgny-button-next($padding: 4px, $image: '~images/fgny/icons/button-arrow-hover.svg');
          }
        }
      }
      @include if-theme('fgny') {
        color: #000;
        &:after {
          display: none;
        }
        &:hover, &.active {
          color: #3737E6;
        }
      }
    }

    &.light {
      border-color: $brand-primary-text;
      color: $brand-primary-text;
    }

    &.scrollto {
      background: $green-button-bg;
      border-color: $green-button-bg;
      color: $brand-primary-text;
    }

    &.small {
      @include if-theme {
        @include rem(padding, units(.2) units(1));
        @include font('xi');
        margin: auto 0;
      }
    }

    &.disabled,
    &:disabled {
      border: 1px solid $neutral-grey-5;
      color: $neutral-grey-5;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &--round {
    @include rem(padding, units(1) units(2));
    background: $neutral-grey-2;
    @include if-theme() {
      color: $neutral-grey-9;
      border-radius: 100px;
    }
    font-weight: $font-weight-medium;

    &:hover {
      background: darken($neutral-grey-2, 5%);
    }

    &.disabled,
    &:disabled {
      opacity: .5;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &--play {
    border-radius: 100px;
    background: $brand-primary-text;
    color: $neutral-grey-9;
    text-transform: uppercase;
  }

  &--small {
    @include font('xi');
    @include rem(padding, units(1));
  }

  &--flush {
    margin: 0;
  }
}

.btn__text {
  &--desk {
    @include breakpoint('palm', 'xxsmall') {
      display: none;
    }
  }

  &--xsml {
    @include breakpoint('xxsmall') {
      display: none;
    }
  }
}

.book-btn {
  margin: 8px 0;
}
